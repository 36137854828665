import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import ThirdColumnContainer from "./styles";
import ChatHeader from "./chatHeader/ChatHeader";
import ChatInputComponent from "./chatInputComponent/ChatInputComponent";
import Chat from "./chat/Chat";
import useGetData from "../../../hooks/useGetData";
import axios from "axios";
import ReactTostify from "../../../common/ReactTostify";
import { InstanceWithAuth } from "../../../App";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";
import Commets from "./commets/Commets";
import ColumnHerder from "./columnHerder/ColumnHerder";
import LiveChat from "./liveChat/LiveChat";
import Swal from "sweetalert2";
import MailsAndComments from "./mailsAndComments/MailsAndComments";
import { handleRefetchMailThread } from "../../../redux/app/appSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DataNotFound from "../../../common/DataNotFound";

const ThirdColumn = ({
  selectedEmailId,
  showDrawerContactDrawer,
  selectedEmails,
  setSelectedEmails,
  id,
  selectedFolder,
  refetchData,
  email,
  loadingEmailThread,
  refetchEmails,
}) => {
  const { mailID } = useParams();
  const { auth, app } = useSelector((state) => state);
  // const {
  //   data: email,
  //   error,
  //   loading: loadingEmailThread,
  //   refetchData,
  // } = useGetData(
  //   `support/emails/${selectedEmailId ? selectedEmailId : mailID}`
  // );
  const { data: notes, refetchData: refetchNotes } = useGetData(
    selectedFolder == "Chat" ||
      (selectedFolder == "All Open" &&
        app?.allOpenConversationDetails?.Model == "LiveChat")
      ? `live-chat/notes/${selectedEmailId}`
      : `support/emails/notes/${selectedEmailId ? selectedEmailId : mailID}`
  );

  const {
    data: chatThread,
    loading: chatLoading,
    error: chatLoadingError,
    refetchData: refetchThread,
  } = useGetData(`live-chat/${selectedEmailId}`);

  const [currentEmployeeImage, setCurrentEmployeeImage] = useState("");
  const [selectedTab, setSelectedTab] = useState("Email");

  const [savingComment, setSavingComment] = useState(false);
  const [sendingMail, setSendingMail] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const [currentChatThread, setCurrentChatThread] = useState();
  const [isForwardMail, setIsForwardMail] = useState(false);
  const [forwardMailDetails, setForwardMailDetails] = useState("");
  const [clearInputtext, setClearInputtext] = useState(true);
  const [forwardFiles, setForwardFiles] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    refetchData();
    refetchNotes();
  }, [app?.isEmailThreadUpdated]);

  const readMessage = async () => {
    try {
      const response = await InstanceWithAuth.get(
        `support/emails/${selectedEmailId ? selectedEmailId : mailID}/read`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response?.status === 200 && response?.status === 201) {
        refetchEmails();
      }
    } catch (error) {
      console.error("Failed to fetch companies", error);
    }
  };

  useEffect(() => {
    if (email?.data?.read_status !== 1) {
      readMessage();
    }
  }, [email]);

  const handleRefetcThreads = () => {
    dispatch(handleRefetchMailThread(!app?.isEmailThreadUpdated));
  };

  useEffect(() => {
    setIsForwardMail(false);
    setForwardMailDetails("");
  }, [selectedEmailId]);

  const handleSend = async (data) => {
    setSendingMail(true);
    const { to, cc, bcc, message, files } = data;
    const formData = new FormData();

    const cleanedMessage = message.replace(/<p><br><\/p>$/, "").trim();

    const fromName = `${
      currentEmployeeImage?.first_name || auth?.userObj?.first_name
    } ${currentEmployeeImage?.last_name || auth?.userObj?.last_name}`;

    formData.append("from_name", fromName);

    formData.append("message_body", cleanedMessage);

    to.forEach((recipient, index) =>
      formData.append(`to[${index}]`, recipient)
    );
    cc.forEach((recipient, index) =>
      formData.append(`cc[${index}]`, recipient)
    );
    bcc.forEach((recipient, index) =>
      formData.append(`bcc[${index}]`, recipient)
    );

    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/reply/${selectedEmailId ? selectedEmailId : mailID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        // ReactTostify("success", response?.data?.message);
        setClearInputtext(!clearInputtext);
        handleRefetcThreads();
      }
    } catch (error) {
    } finally {
      setSendingMail(false);
    }
  };

  const handleForward = async (data) => {
    setSendingMail(true);
    const { to, cc, bcc, message, files } = data;
    const cleanedMessage = message.replace(/<p><br><\/p>$/, "").trim();

    const formData = new FormData();
    formData.append(
      "from_name",
      `${currentEmployeeImage?.first_name} ${currentEmployeeImage?.last_name}`
    );
    formData.append("message_body", cleanedMessage);

    to.forEach((recipient, index) =>
      formData.append(`to[${index}]`, recipient)
    );
    to.forEach((recipient, index) => formData.append(`email`, recipient));
    cc.forEach((recipient, index) =>
      formData.append(`cc[${index}]`, recipient)
    );
    bcc.forEach((recipient, index) =>
      formData.append(`bcc[${index}]`, recipient)
    );

    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/forward/${selectedEmailId ? selectedEmailId : mailID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setClearInputtext(!clearInputtext);
        ReactTostify("success", response?.data?.message);
        handleRefetcThreads();
      }
    } catch (error) {
      ReactTostify("error", error?.response?.data?.message);
    } finally {
      setSendingMail(false);
    }
  };
  const handleLiveChatSend = async (data) => {
    setSendingMail(true);
    const { message, files } = data;
    const formData = new FormData();

    const cleanedMessage = message.replace(/<p><br><\/p>$/, "").trim();

    formData.append("message", cleanedMessage);
    const mostRecentThread = currentChatThread?.data?.threads
      ? Object.entries(currentChatThread.data.threads)[0][1]
      : [];

    if (mostRecentThread.length > 0) {
      const firstItem = mostRecentThread[0];
      formData.append("live_chat_id", firstItem.live_chat_id);
    }

    formData.append("user_id", auth?.userObj?.id);

    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });

    try {
      const response = await InstanceWithAuth.post(
        `live-chat/reply-to-chat`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        // ReactTostify("success", response?.data?.message);
        // refetchData()
        setClearInputtext(!clearInputtext);
        handleRefetcThreads();
      }
    } catch (error) {
      ReactTostify("error", error?.response?.data?.message);
      setSendingMail(false);
    } finally {
      setSendingMail(false);
    }
  };

  const handleCommentSend = async (data) => {
    setSavingComment(true);
    const { comment, files } = data;
    const cleanedMessage = comment.replace(/<p><br><\/p>$/, "").trim();

    const formData = new FormData();
    formData.append(
      "from_name",
      `${currentEmployeeImage?.first_name} ${currentEmployeeImage?.last_name}`
    );
    formData.append("notes", cleanedMessage);

    // if (editingNote) {
    //   formData.append(`attachments[]`, "");
    // } else {
    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });
    // }

    try {
      let response;
      if (editingNote) {
        response = await InstanceWithAuth.post(
          selectedFolder == "Chat" ||
            (selectedFolder == "All Open" &&
              app?.allOpenConversationDetails?.Model == "LiveChat")
            ? `/live-chat/notes/${editingNote.id}/update`
            : `support/emails/notes/${editingNote.id}/update`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
      } else {
        response = await InstanceWithAuth.post(
          selectedFolder == "Chat" ||
            (selectedFolder == "All Open" &&
              app?.allOpenConversationDetails?.Model == "LiveChat")
            ? `/live-chat/notes/${selectedEmailId ? selectedEmailId : mailID}`
            : `support/emails/notes/${
                selectedEmailId ? selectedEmailId : mailID
              }`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
      }

      if (response.status === 201 || response.status === 200) {
        setClearInputtext(!clearInputtext);
        // Swal.fire({
        //   icon: "success",
        //   title: "Success!",
        //   text: response?.data?.message,
        //   customClass: {
        //     confirmButton: "custom-swal-button",
        //   },
        // });
        refetchNotes();
        handleRefetcThreads();
      }
    } catch (error) {
    } finally {
      setSavingComment(false);
      setEditingNote(null);
    }
  };

  const handleEditNote = (note) => {
    setEditingNote(note);
  };
  const handleForwardMail = (emailData) => {
    setSelectedTab("Email");
    setIsForwardMail(true);
    setForwardMailDetails(emailData);
  };
  const handleReplyEmail = () => {
    setSelectedTab("Email");
    setIsForwardMail(false);
    setForwardMailDetails("");
  };
  return (
    <ThirdColumnContainer>
      {selectedEmailId !== null || mailID ? (
        <>
          <ColumnHerder
            showDrawerContactDrawer={showDrawerContactDrawer}
            selectedEmailId={selectedEmailId ? selectedEmailId : mailID}
            email={email}
            selectedEmails={selectedEmails}
            selectedFolder={selectedFolder}
            loadingEmailThread={loadingEmailThread}
          />
          <ChatHeader
            selectedEmailId={selectedEmailId ? selectedEmailId : mailID}
            setCurrentEmployeeImage={setCurrentEmployeeImage}
            currentEmployeeImage={currentEmployeeImage}
            email={email}
            selectedFolder={selectedFolder}
          />
          {selectedFolder == "Chat" ||
          (selectedFolder == "All Open" &&
            app?.allOpenConversationDetails?.Model == "LiveChat") ? (
            <LiveChat
              email={email}
              currentEmployeeImage={currentEmployeeImage}
              selectedEmailId={selectedEmailId ? selectedEmailId : mailID}
              setCurrentChatThread={setCurrentChatThread}
              onEdit={handleEditNote}
              setSelectedTab={setSelectedTab}
              notes={notes}
              refetchNotes={refetchNotes}
              chatThread={chatThread}
              chatLoading={chatLoading}
              chatLoadingError={chatLoadingError}
              refetchThread={refetchThread}
            ></LiveChat>
          ) : (
            <>
              {/* {selectedTab === 'Email' ? (
                  <Chat
                    email={email}
                    currentEmployeeImage={currentEmployeeImage}
                  />
                ) : (
                  <Commets
                    selectedEmailId={selectedEmailId}
                    refetchNotes={refetchNotes}
                    notes={notes}
                    onEdit={handleEditNote}
                  />
                )} */}

              <MailsAndComments
                email={email}
                currentEmployeeImage={currentEmployeeImage}
                selectedEmailId={selectedEmailId ? selectedEmailId : mailID}
                refetchNotes={refetchNotes}
                notes={notes}
                handleForwardMail={handleForwardMail}
                handleReplyEmail={handleReplyEmail}
                loadingEmailThread={loadingEmailThread}
                onEdit={handleEditNote}
                setSelectedTab={setSelectedTab}
              ></MailsAndComments>
            </>
          )}
          <ChatInputComponent
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            onSend={
              selectedFolder == "Chat" ||
              (selectedFolder == "All Open" &&
                app?.allOpenConversationDetails?.Model == "LiveChat")
                ? handleLiveChatSend
                : isForwardMail
                ? handleForward
                : handleSend
            }
            handleCommentSend={handleCommentSend}
            savingComment={savingComment}
            email={email}
            sendingMail={sendingMail}
            notes={notes}
            editingNote={editingNote}
            selectedFolder={selectedFolder}
            forwardMailDetails={forwardMailDetails}
            isForwardMail={isForwardMail}
            handleReplyEmail={handleReplyEmail}
            clearInputtext={clearInputtext}
            forwardFiles={forwardFiles}
            setForwardFiles={setForwardFiles}
            chatThread={chatThread}
          />
        </>
      ) : null}
    </ThirdColumnContainer>
  );
};

export default ThirdColumn;
