import styled from "styled-components";

const FirstColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-right: 1px solid #e3eced;
  padding: 0px 0px;
  pag: 20px;

  .compose-button {
    display: flex;
    padding: 6px 12px;
    ${"" /* max-height: 10px; */}
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
    margin: 10px;
  }

  .sidebar-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${"" /* width: 100%; */}
    margin-top: 10px;
    cursor: pointer;
    padding: 3px 7px;
    border-radius: 3px;
    margin-left: 10px;
    margin-right: 10px;

    .right {
      display: flex;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
        flex-shrink: 0;
      }

      .icon {
        margin-right: 10px;
        width: 12px;
        height: 12px;
        margin-top: -5px;
      }

      .label {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.49px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .left {
      color: var(--White-100, #fff);
      text-align: center;
      font-family: Outfit;
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;
      background-color: #2b3746;
      right: 2px;
      border-radius: 5px;
      padding-left: 5px;
      padding-right: 5px;
    }

    &:hover {
      .left {
        background-color: #4cbbcb;
      }
      .label {
        color: #4cbbcb;
      }
      .right {
        svg {
          ${"" /* fill: #4cbbcb; */}
        }
      }
    }

    &.active {
      background-color: var(--Blue-20, rgba(76, 187, 203, 0.2));

      .left {
        background-color: #4cbbcb;
      }
      .label {
        color: #4cbbcb;
      }
      .right {
        svg {
          ${"" /* fill: #4cbbcb; */}
        }
      }
    }
  }

  .small-sidebar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 12px 2px 0px 15px;
    border-radius: 3px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    margin-top: 20px;

    .right {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 10px;
        width: 25px;
        height: 25px;
        margin-top: -5px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    .left {
      color: var(--White-100, #fff);
      text-align: center;
      font-family: Outfit;
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;
      background-color: #2b3746;
      right: 2px;
      border-radius: 5px;
      padding-left: 5px;
      padding-right: 5px;
      position: absolute;
      top: -4px;
      right: 10px;
    }

    &:hover {
      .left {
        background-color: #4cbbcb;
      }
      .label {
        color: #4cbbcb;
      }
      .right {
        svg {
          ${"" /* fill: #4cbbcb; */}
        }
      }
    }

    &.active {
      background-color: var(--Blue-20, rgba(76, 187, 203, 0.2));

      .left {
        background-color: #4cbbcb;
      }
      .label {
        color: #4cbbcb;
      }
      .right {
        svg {
          ${"" /* fill: #4cbbcb; */}
        }
      }
    }
  }
`;

export { FirstColumnContainer };
