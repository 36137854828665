import React, { useEffect, useRef, useState } from "react";
import { Select } from "antd";
import PipelineModel from "../../../modals/pipelineModel/PipelineModel";
import { StatusAndPiplineContainer } from "./styles";
import useGetData from "../../../../hooks/useGetData";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import ReactTostify from "../../../../common/ReactTostify";

const { Option } = Select;

const StatusAndPipline = ({ ticket, refetchData }) => {
  const {
    data: pipelines,
    error: pipelinesError,
    loading,
    refetchData: refetchStatus,
  } = useGetData(`support/tickets/pipelines?status=1`);

  const [isPipelineModelOpen, setIsPipelineModelOpen] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { auth } = useSelector((state) => state);

  // Update pipeline and status options when pipelines or ticket change
  useEffect(() => {
    if (pipelines && ticket) {
      const defaultPipeline = pipelines?.data?.find(
        (p) => p.id === ticket.support_pipeline_id
      );
      if (defaultPipeline) {
        setSelectedPipeline(defaultPipeline);
        setStatusOptions(defaultPipeline.stages || []);

        const defaultStage = defaultPipeline.stages.find(
          (stage) => stage.id === ticket.support_pipeline_stage_id
        );
        console.log("defaultPipeline", defaultPipeline);
        console.log("defaultStage", defaultStage);
        console.log("id", ticket.support_pipeline_stage_id);
        setSelectedStatus(defaultStage?.name || null);
      }
    }
  }, [pipelines, ticket]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleStatusChange = async (value) => {
    setIsOpen(!isOpen);
    try {
      await InstanceWithAuth.put(
        `support/tickets/${ticket.id}`,
        { support_pipeline_stage_id: value },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Status Updated!",
        text: "The status has been updated successfully.",
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
      await refetchData();
    } catch (error) {
        ReactTostify("error", error?.response?.data?.message);
    }
  };

  const handlePipelineChange = (value) => {
    const pipeline = pipelines.data.find((p) => p.id === value);
    setSelectedPipeline(pipeline);
    setStatusOptions(pipeline?.stages || []);
  };

  const handleSave = () => {
    console.log("Selected Pipeline:", selectedPipeline);
    console.log("Pipeline Statuses:", statusOptions);
  };

  const handleOk = async () => {
    setIsPipelineModelOpen(false);
    await refetchData();
  };

  const handleCancel = () => {
    setIsPipelineModelOpen(false);
  };

  const showModal = () => {
    setIsPipelineModelOpen(true);
  };

  const TruncateText = ({ text }) => {
    const maxLength = 30;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  return (
    <StatusAndPiplineContainer>
      <div className="make-row">
        <span className="label">Status: </span>
        <div className="custom-dropdown" ref={dropdownRef}>
          <div className="dropdown-header" onClick={toggleDropdown}>
            <div className="user-name">
              <TruncateText
                text={selectedStatus || "Loading..."}
              ></TruncateText>
            </div>
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke="#4CBBCB"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke="#556373"
                  strokeOpacity="0.2"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          {isOpen && (
            <ul className="dropdown-list">
              {statusOptions?.map((status) => (
                <li
                  key={status.id}
                  className="dropdown-item"
                  onClick={() => handleStatusChange(status.id)}
                >
                  {status.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="make-row">
        <span className="label">Pipeline: </span>
        <span onClick={showModal} className="pipeline">
          {selectedPipeline?.name || "Select a pipeline"}
        </span>
      </div>

      <PipelineModel
        isPipelineModelOpen={isPipelineModelOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        pipelines={pipelines?.data}
        selectedPipeline={selectedPipeline}
        statusOptions={statusOptions}
        ticket={ticket}
        setStatusOptions={setStatusOptions}
      />
    </StatusAndPiplineContainer>
  );
};

export default StatusAndPipline;
