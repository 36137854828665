import React, { useState, useRef, useEffect } from "react";
import { MainContainer } from "./styles";
import ChatInput from "./chatInput/ChatInput";
import ChatMessages from "./ChatMessages/ChatMessages";
import { Skeleton } from "antd";
import { InstanceWithAuth } from "../../../../App";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { MdPhotoSizeSelectSmall } from "react-icons/md";

const ChatSection = ({
  directMessage,
  loadingDirectMessage,
  refetchDirectMessage,
  selectedDirectMessage,
  firstRender,
  setFirstRender,
  quotedMessage,
  setQuotedMessage,
  handleAddSession,
}) => {
  const [mainDivWidth, setMainDivWidth] = useState(100);
  // const [quotedMessage, setQuotedMessage] = useState(null);
  const [value, setValue] = useState("");
  const { auth } = useSelector((state) => state);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const containerRef = useRef(null);
  const chatDivRef = useRef(null);

  useEffect(() => {
    if (chatDivRef.current) {
      // Scroll to the bottom on the first render
      if (firstRender || (scrollToBottom && directMessage)) {
        chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
        setFirstRender(false);
        setScrollToBottom(false);
      } else {
        // Check if the user is at or near the bottom
        const isAtBottom =
          chatDivRef.current.scrollHeight - chatDivRef.current.scrollTop <=
          chatDivRef.current.clientHeight + 50;

        if (isAtBottom) {
          chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
        }
      }
    }
  }, [directMessage]);

  const handleSendMessage = async (message, files) => {
    setQuotedMessage(null);
    setValue("");
    setSendingMessage(true);

    const formData = new FormData();
    formData.append("message", message || ""); // Handle cases where message is empty
    formData.append("reply_to", quotedMessage ? quotedMessage.id : "");

    // Add files to FormData
    if (files && files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`attachments[${index}]`, file, file.name);
      });
    }

    const endpoint = quotedMessage
      ? `internal-chat/sessions/${directMessage?.data?.id}/message/${quotedMessage?.id}`
      : `internal-chat/sessions/${directMessage?.data?.id}/message`;

    try {
      const response = await InstanceWithAuth.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      if (response.status === 201) {
        setQuotedMessage(null);
        setValue("");
        setSelectedFiles([]);
        setSendingMessage(false);
        await refetchDirectMessage();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error.response?.data?.message,
      });
      setSendingMessage(false);
    }
  };

  const getInitials = () => {
    const { first_name, last_name } = selectedDirectMessage;
    if (first_name && last_name) {
      return (first_name[0] + last_name[0]).toUpperCase();
    } else if (first_name) {
      return first_name.substring(0, 2).toUpperCase();
    } else if (last_name) {
      return last_name.substring(0, 2).toUpperCase();
    }
    return "";
  };

  return (
    <MainContainer mainDivWidth={mainDivWidth} ref={containerRef}>
      <div className="main-div">
        <div className="header">
          <div className="left">
            {selectedDirectMessage?.avatar ? (
              <span>
                <img
                  className="img"
                  src={selectedDirectMessage.avatar}
                  alt="employee avatar"
                />
              </span>
            ) : (
              <span className="initials-avatar">{getInitials()}</span>
            )}
            <span className="name">
              {selectedDirectMessage?.first_name +
                " " +
                selectedDirectMessage?.last_name}
            </span>
          </div>
          <div className="right">
            <span className="icon" onClick={() => handleAddSession()}>
              <MdPhotoSizeSelectSmall size={20} color="#2b3746" />
            </span>
          </div>
        </div>

        <div className="chat-div" ref={chatDivRef}>
          {loadingDirectMessage && firstRender ? (
            <>
              <Skeleton className="skelton-wrapper" active />
            </>
          ) : (
            <ChatMessages
              setQuotedMessage={setQuotedMessage}
              directMessage={directMessage}
              refetchDirectMessage={refetchDirectMessage}
            />
          )}
        </div>

        <div className="chat-input">
          <ChatInput
            quotedMessage={quotedMessage}
            setQuotedMessage={setQuotedMessage}
            handleSendMessage={(message) =>
              handleSendMessage(message, attachments)
            }
            value={value}
            setValue={setValue}
            sendingMessage={sendingMessage}
            handleAttachmentsChange={setAttachments}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </div>
      </div>
    </MainContainer>
  );
};

export default ChatSection;
