// src/features/counterSlice.js
import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    mailParentPage: "",
    mailDetailsToEdit: "",
    currentPage: "",
    sidebarCollapsed: true,
    mailBoxLocationId: "",
    userFlowSelectedTab: "1",
    companyFlowSelectedTab: "1",
    userMailBoxDetails: "",
    userSelectedLocation: "",
    userSelcetedPipeLine: { name: "Onboarding", id: 1 },
    userSelcetedTicketType: "Task",
    isMailCounterChanged: false,
    isEmailThreadUpdated: false,
    currentActiveChatThread: "",
    currentActiveInboxTab: "",
    currentActiveInboxTabKey: "5",
    showContractedChat: false,
    allOpenConversationDetails: "",
    currentActiveMailboxStats: "",
    internalChatsSpaces: [],
    quotedMessage: null,
    threadQuotedMessage: null,
    openThread: null,
    internalChatsSessions: [],
    appliedMailFilters: "",
    supportSearchShow: false,
    selectedMailProfileForGroupForward: "",
    nextForwardMailsList: "",
};

export const appSlice = createSlice({
    name: "AppAuth",
    initialState,
    reducers: {
        handleCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        handleCollapsed: (state) => {
            state.sidebarCollapsed = !state.sidebarCollapsed;
        },
        handMailBoxLocationId: (state, action) => {
            state.mailBoxLocationId = action.payload;
        },
        handSelectUserFlowTab: (state, action) => {
            state.userFlowSelectedTab = action.payload;
        },
        handSelectCompanyFlowTab: (state, action) => {
            state.companyFlowSelectedTab = action.payload;
        },
        handleAddUserMailBoxDetails: (state, action) => {
            state.userMailBoxDetails = action.payload;
        },
        handleAddUserelectedLocation: (state, action) => {
            state.userSelectedLocation = action.payload;
        },
        handleUserSelcetedPipeLine: (state, action) => {
            state.userSelcetedPipeLine = action.payload;
        },

        handleUserSelcetedTicketType: (state, action) => {
            state.userSelcetedTicketType = action.payload;
        },

        handleAddMailDetailsToEdit: (state, action) => {
            state.mailDetailsToEdit = action.payload;
        },
        handleAddMailParentPageToEdit: (state, action) => {
            state.mailParentPage = action.payload;
        },
        handleRefetchMailCounter: (state, action) => {
            state.isMailCounterChanged = action.payload;
        },
        handleRefetchMailThread: (state, action) => {
            state.isEmailThreadUpdated = action.payload;
        },
        handleAddCurrentActiveChatThread: (state, action) => {
            state.currentActiveChatThread = action.payload;
        },
        handleAddCurrentActiveChatTab: (state, action) => {
            state.currentActiveInboxTab = action.payload;
        },
        handleAddCurrentActiveChatTabKey: (state, action) => {
            state.currentActiveInboxTabKey = action.payload;
        },
        handleShowContractedChat: (state, action) => {
            state.showContractedChat = action.payload;
        },
        handleAddOpenConversationDetails: (state, action) => {
            state.allOpenConversationDetails = action.payload;
        },
        handleAddCurrentActiveMailboxStats: (state, action) => {
            state.currentActiveMailboxStats = action.payload;
        },
        handleUpdateChatOwner: (state, action) => {
            const { owner } = action.payload;
            state.allOpenConversationDetails = {
                ...state.allOpenConversationDetails,
                owner,
            };
        },
        handleAddInternalChatSpace: (state, action) => {
            state.internalChatsSpaces = state.internalChatsSpaces || [];
            const existingSpace = state.internalChatsSpaces.find(
                (space) => space.id === action.payload.id
            );
            if (!existingSpace) {
                state.internalChatsSpaces.push(action.payload);
            }
        },

        handleRemoveInternalChatSpace: (state, action) => {
            state.internalChatsSpaces = state.internalChatsSpaces || [];
            state.internalChatsSpaces = state.internalChatsSpaces.filter(
                (space) => space.id !== action.payload.id
            );
        },
        setQuotedMessage: (state, action) => {
            state.quotedMessage = action.payload;
        },
        setThreadQuotedMessage: (state, action) => {
            state.threadQuotedMessage = action.payload;
        },
        setOpenThread: (state, action) => {
            state.openThread = action.payload;
        },

        handleAddInternalChatSession: (state, action) => {
            state.internalChatsSessions = state.internalChatsSessions || [];
            const existingSessions = state.internalChatsSessions.find(
                (session) => session.id === action.payload.id
            );
            if (!existingSessions) {
                state.internalChatsSessions.push(action.payload);
            }
        },

        handleRemoveInternalChatSession: (state, action) => {
            state.internalChatsSessions = state.internalChatsSessions || [];
            state.internalChatsSessions = state.internalChatsSessions.filter(
                (session) => session.id !== action.payload.id
            );
        },
        handleAddAppliedMialFilters: (state, action) => {
            state.appliedMailFilters = action.payload;
        },
        handleSupportSearchShow: (state) => {
            state.supportSearchShow = !state.supportSearchShow;
        },
        handleAddMailProfileForGroupForward: (state, action) => {
            state.selectedMailProfileForGroupForward = action.payload;
        },
        handleAddNextForwardMailsList: (state, action) => {
            state.nextForwardMailsList = action.payload;
        },
    },
});

export const {
    handleCurrentPage,
    handleCollapsed,
    handMailBoxLocationId,
    handSelectUserFlowTab,
    handSelectCompanyFlowTab,
    handleAddUserMailBoxDetails,
    handleAddUserelectedLocation,
    handleUserSelcetedPipeLine,
    handleUserSelcetedTicketType,
    handleAddMailDetailsToEdit,
    handleAddMailParentPageToEdit,
    handleRefetchMailCounter,
    handleRefetchMailThread,
    handleAddCurrentActiveChatThread,
    handleAddCurrentActiveChatTab,
    handleAddCurrentActiveChatTabKey,
    handleShowContractedChat,
    handleAddOpenConversationDetails,
    handleUpdateChatOwner,
    handleAddCurrentActiveMailboxStats,
    handleAddInternalChatSpace,
    handleRemoveInternalChatSpace,
    setQuotedMessage,
    setThreadQuotedMessage,
    setOpenThread,
    handleAddInternalChatSession,
    handleRemoveInternalChatSession,
    handleAddAppliedMialFilters,
    handleSupportSearchShow,
    handleAddMailProfileForGroupForward,
    handleAddNextForwardMailsList,
} = appSlice.actions;

export default appSlice.reducer;