import React, { useState, useRef, useEffect } from "react";
import { MainContainer } from "./styles"; // Import CSS for styling
import useGetData from "../../../hooks/useGetData";
import { handleUserSelcetedPipeLine } from "../../../redux/app/appSlice";
import { useDispatch } from "react-redux";
import { Button, message, Space } from "antd";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
const TicketBoardTypeDropDown = ({
  selectedPipeline,
  setSelectedPipeline,
  app,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    data: pipeLineData,
    loading,
    refetchData,
    error,
  } = useGetData("support/tickets/pipelines");

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {};

  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setIsOpen(!isOpen);
    setSelectedPipeline(option);
    dispatch(handleUserSelcetedPipeLine(option));
    app?.userSelcetedPipeLine?.name != option?.name &&
      messageApi.open({
        type: "success",
        content: `Pipeline has been set to ${option?.name}`,
      });
  };

  const filteredPipeLineData = pipeLineData?.data?.filter((pipeline) =>
    app?.userSelcetedTicketType
      ? pipeline.type === app?.userSelcetedTicketType?.toLowerCase()
      : true
  );

  useEffect(() => {
    if (app?.userSelcetedTicketType?.toLowerCase() === "task") {
      const onboardingPipeline = { name: "Onboarding", id: 1 };
      setSelectedPipeline(onboardingPipeline);
      dispatch(handleUserSelcetedPipeLine(onboardingPipeline));
    } else if (app?.userSelcetedTicketType?.toLowerCase() === "ticket" && filteredPipeLineData?.length > 0) {
      const firstPipeline = filteredPipeLineData[0];
      setSelectedPipeline(firstPipeline);
      dispatch(handleUserSelcetedPipeLine(firstPipeline));
    }
  }, [app?.userSelcetedTicketType?.toLowerCase()]);

  return (
    <MainContainer>
      {contextHolder}
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">
            {app?.userSelcetedPipeLine
              ? app?.userSelcetedPipeLine?.name
              : "Select PipeLine"}
          </div>
          {isOpen ? (
            <MdOutlineKeyboardArrowDown fill="rgb(76, 187, 203)"></MdOutlineKeyboardArrowDown>
          ) : (
            <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
          )}
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            {filteredPipeLineData?.map((pipeLine) => (
              <li onClick={() => handleOptionSelect(pipeLine)}>
                {pipeLine?.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </MainContainer>
  );
};

export default TicketBoardTypeDropDown;
