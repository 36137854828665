import React from "react";
import GetLogo from "../getlogo/getlogo";
import { MainConatiner } from "./styles";
function StatusLabelButton({ Data }) {
  return (
    <MainConatiner>
      <button className={Data?.user?.online ? "manager-btn" : "manager-btn"}>
        <div
          className={
            Data?.status_label == "On Hold" && Data?.status == 0
              ? "on-hold"
              : Data?.status == 0
              ? "in-active"
              : "active"
          }
        >
          <span className="btn-icon">{GetLogo("circle")}</span>
          <span className="text">
            {Data?.status_label
              ? Data?.status_label
              : Data?.status === 1
              ? "Active"
              : "Inactive"}
          </span>
        </div>
      </button>
    </MainConatiner>
  );
}

export default StatusLabelButton;
