import React, { useEffect, useState } from "react";
import { Form, Modal, Select, Button, Input } from "antd";
import { RxCross2 } from "react-icons/rx";
import { Container, MainConatiner, CraeteTicketContainer } from "./styles";
import TextArea from "antd/lib/input/TextArea";
import { InstanceWithAuth } from "../../../../../../App";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { handleAddOpenConversationDetails } from "../../../../../../redux/app/appSlice";
import { useParams } from "react-router-dom";
import ReactTostify from "../../../../../../common/ReactTostify";

const { Option } = Select;

const CraeteTicket = ({
  isPipelineModelOpen,
  handleCancel,
  handleOk,
  pipelines,
  selectedPipeline,
  stages,
  onPipelineChange,
  selectedEmailId,
  refetchData,
  email,
  refetchEmail,
}) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { auth, app } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { mailID } = useParams();

  const handleSave = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setIsSaving(true); // Disable the button and change the title to "Saving..."
        try {
          const response = await InstanceWithAuth.post(
            `support/tickets/email-to-ticket/${selectedEmailId}`,
            {
              support_pipeline_id: values.pipeline,
              support_pipeline_stage_id: values.stage,
              description: values.description,
              priority: values.priority,
              user_id: auth?.userObj?.id,
              email: values.email,
              ...(email?.data?.is_unknown_visitor && {
                first_name: values.firstName,
                last_name: values.lastName,
              }),
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${auth?.userObj?.api_token}`,
              },
            }
          );

          if (response?.status === 201) {
            form.resetFields();
            refetchEmail();
            handleOk();
            const response = await InstanceWithAuth.get(
              `support/emails/${selectedEmailId}`,
              {
                headers: {
                  Authorization: `Bearer ${auth?.userObj?.api_token}`,
                },
              }
            );
            if (response.status === 200 || response.status === 201) {
              dispatch(handleAddOpenConversationDetails(response?.data?.data));
            }

            ReactTostify("success", response?.data?.message);
          }
        } catch (error) {
          refetchData();
        ReactTostify("error", error?.response?.data?.message);
        } finally {
          setIsSaving(false);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (selectedPipeline) {
      form.setFieldsValue({ pipeline: selectedPipeline.id });
    }
  }, [selectedPipeline]);

  useEffect(() => {
    console.log("Mohsin", app?.allOpenConversationDetails);
    if (app?.allOpenConversationDetails?.sender_name) {
      console.log("salman", app?.allOpenConversationDetails);

      const [firstName, ...lastNameArray] =
        app?.allOpenConversationDetails?.sender_name?.split(" ");
      const lastName = lastNameArray?.join(" ");

      form.setFieldsValue({
        firstName: firstName || "",
        lastName: lastName || "",
        email: app?.allOpenConversationDetails?.sender_email || "",
      });
    } else {
      console.log("hamza", app?.allOpenConversationDetails);
      form.setFieldsValue({
        firstName: app?.allOpenConversationDetails?.contact_id
          ? `${
              app?.allOpenConversationDetails?.contact?.first_name || ""
            }`.trim()
          : app?.allOpenConversationDetails?.guest_id
          ? `${app?.allOpenConversationDetails?.guest?.first_name || ""}`.trim()
          : "",
        lastName: app?.allOpenConversationDetails?.contact_id
          ? `${
              app?.allOpenConversationDetails?.contact?.last_name || ""
            }`.trim()
          : app?.allOpenConversationDetails?.guest_id
          ? `${app?.allOpenConversationDetails?.guest?.last_name || ""}`.trim()
          : "",
        email: app?.allOpenConversationDetails?.contact_id
          ? `${app?.allOpenConversationDetails?.contact?.email || ""}`.trim()
          : app?.allOpenConversationDetails?.guest_id
          ? `${app?.allOpenConversationDetails?.guest?.email || ""}`.trim()
          : "",
      });
    }
  }, [app?.allOpenConversationDetails, form]);

  return (
    <CraeteTicketContainer>
      <Form form={form} requiredMark={false}>
        <MainConatiner>
          <Container>
            <div className="form-container">
              {email?.data?.is_unknown_visitor ? (
                <>
                  <div className="form-main">
                    <div className="form">
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        className="form-items"
                        rules={[
                          {
                            required: true,
                            message: "Please enter first name!",
                          },
                        ]}
                      >
                        <Input className="location" placeholder="First Name" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="form-main">
                    <div className="form">
                      <Form.Item
                        name="lastName"
                        label="Last Name"
                        className="form-items"
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name!",
                          },
                        ]}
                      >
                        <Input className="location" placeholder="Last Name" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="form-main">
                    <div className="form">
                      <Form.Item
                        name="email"
                        label="Email"
                        className="form-items"
                        rules={[
                          {
                            required: true,
                            message: "Please enter email",
                          },
                        ]}
                      >
                        <Input className="location" placeholder="Email" />
                      </Form.Item>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    name="pipeline"
                    label="Pipeline"
                    className="form-items"
                    rules={[
                      { required: true, message: "Please select a pipeline!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Select a pipeline"
                      onChange={onPipelineChange}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {pipelines?.map((pipeline) => (
                        <Option key={pipeline.id} value={pipeline.id}>
                          {pipeline.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    className="form-items"
                    name="stage"
                    label="Stage"
                    rules={[
                      { required: true, message: "Please select a stage!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Select a stage"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {stages.map((stage) => (
                        <Option key={stage.id} value={stage.id}>
                          {stage.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    className="form-items"
                    name="priority"
                    label="Priority"
                    rules={[
                      { required: true, message: "Please select a priority!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Set priority"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option value="Low">Low</Option>
                      <Option value="Medium">Medium</Option>
                      <Option value="High">High</Option>
                      <Option value="Very High">Very High</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="welcome-message">
                <Form.Item
                  label="Ticket Description"
                  name="description"
                  className="form-items"
                  rules={[
                    { required: true, message: "Please enter a description!" },
                  ]}
                >
                  <TextArea
                    className="input"
                    placeholder="Ticket description here..."
                  />
                </Form.Item>
              </div>
            </div>
          </Container>

          <div className="button-div">
            <button
              className="close-button"
              type="button"
              onClick={handleCancel}
              disabled={isSaving}
            >
              Close
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={isSaving}
              className={isSaving ? "saving" : ""}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </MainConatiner>
      </Form>
    </CraeteTicketContainer>
  );
};

export default CraeteTicket;
