import React, { useEffect, useRef, useState } from "react";
import {
  MessageBodyContainer,
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
} from "./styles";
import { IoReturnUpForward, IoClose } from "react-icons/io5";
import { FiPaperclip } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { Select } from "antd";
import { Quill } from "react-quill";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { InstanceWithAuth } from "../../../../../App";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

Quill.register("modules/emoji", Emoji);

const { Option } = Select;

const MessageBody = ({
  onFileSelect,
  onInputChange,
  handleSend,
  sendingMail,
  email,
}) => {
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [value, setValue] = useState("");
  const [toRecipients, setToRecipients] = useState([]);
  const [ccRecipients, setCcRecipients] = useState([]);
  const [bccRecipients, setBccRecipients] = useState([]);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [fetchedEmails, setFetchedEmails] = useState([]);
  const { auth } = useSelector((state) => state);
  const [searchValue, setSearchValue] = useState("");

  // Populate recipient options with email addresses
  useEffect(() => {
    if (email?.data?.contact?.email) {
      setToRecipients([email?.data?.contact?.email]);
    }
  }, [email?.data]);

  useEffect(() => {
    onInputChange("to", toRecipients);
  }, [toRecipients]);

  useEffect(() => {
    onInputChange("cc", ccRecipients);
  }, [ccRecipients]);

  useEffect(() => {
    onInputChange("bcc", bccRecipients);
  }, [bccRecipients]);

  useEffect(() => {
    if (email?.data?.sender_email) {
      setToRecipients([email?.data?.sender_email]);
    }
  }, [email?.data?.sender_email]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    onFileSelect(event.target.files);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const handleEditorChange = (content) => {
    setValue(content);
    onInputChange("message", content);
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleKeyPress = (event, type) => {
    const emailRegex = /\S+@\S+\.\S+/;
    const inputValue = event.target.value.trim();

    if (event.key === "Enter" && emailRegex.test(inputValue)) {
      // Check which type of recipient to update
      const addEmail = (list, setList) => {
        if (!list.includes(inputValue)) {
          setList([...list, inputValue]);
        }
      };

      if (type === "to") addEmail(toRecipients, setToRecipients);
      if (type === "cc") addEmail(ccRecipients, setCcRecipients);
      if (type === "bcc") addEmail(bccRecipients, setBccRecipients);

      // Clear the input field
      setSearchValue("");
      setTimeout(() => {
        event.target.value = "";
      }, 100);
    }
  };

  const extractEmails = (formattedRecipients) => {
    // Use a regular expression to extract emails from the formatted string
    return formattedRecipients.map((recipient) => {
      const match = recipient.match(
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
      );
      return match ? match[0] : recipient; // Return the email if found, else fallback to the string
    });
  };

  const handleSelectChange = (value, type) => {
    setSearchValue("");
    const emailsOnly = extractEmails(value);
    switch (type) {
      case "to":
        setToRecipients(emailsOnly);
        break;
      case "cc":
        setCcRecipients(emailsOnly);
        break;
      case "bcc":
        setBccRecipients(emailsOnly);
        break;
      default:
        break;
    }
  };

  const TruncateText = ({ text }) => {
    const maxLength = 20;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  const handleSearch = async (searchTerm) => {
    setSearchValue(searchTerm);
    if (!searchTerm) return;

    if (searchTerm === "") {
      setFetchedEmails([]);
    }

    const formData = new FormData();
    formData.append("search", searchTerm);

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/suggestion_emails`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.data?.status === "success") {
        setFetchedEmails(response?.data?.data?.filter(Boolean));
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  const mergeEmails = () => {
    const senderContact = email?.data?.contact;
    const senderEmail = email?.data?.contact?.email;
    const senderName = `${email?.data?.contact?.first_name || ""} ${
      email?.data?.contact?.last_name || ""
    }`.trim();

    const senderCompanyName = email?.data?.company?.name || "";

    const formattedSender = senderName
      ? `${senderName} (${senderEmail})${
          senderCompanyName ? ` (${senderCompanyName})` : ""
        }`
      : `${senderEmail}${senderCompanyName ? ` (${senderCompanyName})` : ""}`;

    const formattedFetchedEmails = fetchedEmails.map((contact) => {
      const { first_name, last_name, email, company_name } = contact;

      const namePart =
        first_name && last_name ? `${first_name} ${last_name}` : "";

      return namePart
        ? `${namePart} (${email})${company_name ? ` (${company_name})` : ""}`
        : `${email}${company_name ? ` (${company_name})` : ""}`;
    });

    const mergedEmails = [
      ...new Set([formattedSender, ...formattedFetchedEmails]),
    ].filter(Boolean);

    return mergedEmails;
  };

  return (
    <MessageBodyContainer>
      {/* To Recipients Field */}
      <div className="main-div">
        <div className="left-div">
          <span className="back-icon">
            <IoReturnUpForward />
          </span>
          <Select
            mode="multiple"
            suffixIcon={
              <MdOutlineKeyboardArrowDown
                size={18}
                width="1rem"
                height="1rem"
              ></MdOutlineKeyboardArrowDown>
            }
            allowClear
            style={{ width: "100%" }}
            placeholder="Enter or choose recipients"
            value={toRecipients}
            onChange={(value) => handleSelectChange(value, "to")}
            onSearch={handleSearch}
            tokenSeparators={[",", ";", " "]}
            notFoundContent={<span>Press enter to add this email</span>}
            onInputKeyDown={(e) => handleKeyPress(e, "to")}
            searchValue={searchValue}
          >
            {mergeEmails(fetchedEmails).map((recipient) => (
              <Option key={recipient} value={recipient}>
                {recipient}
              </Option>
            ))}
          </Select>
        </div>
        <div className="right-div">
          <span className="name" onClick={() => setShowCc(!showCc)}>
            Cc
          </span>
          <span className="name" onClick={() => setShowBcc(!showBcc)}>
            Bcc
          </span>
        </div>
      </div>

      {/* Cc Recipients Field */}
      {showCc && (
        <div className="main-div">
          <div className="left-div">
            <span className="name">Cc</span>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Enter or choose Cc recipients"
              value={ccRecipients}
              onChange={(value) => handleSelectChange(value, "cc")}
              onSearch={handleSearch}
              tokenSeparators={[",", ";", " "]}
              notFoundContent={<span>Press enter to add this email</span>}
              onInputKeyDown={(e) => handleKeyPress(e, "cc")}
              searchValue={searchValue}
            >
              {mergeEmails(fetchedEmails).map((recipient) => (
                <Option key={recipient} value={recipient}>
                  {recipient}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}

      {/* Bcc Recipients Field */}
      {showBcc && (
        <div className="main-div">
          <div className="left-div">
            <span className="name">Bcc</span>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Enter or choose Bcc recipients"
              value={bccRecipients}
              onChange={(value) => handleSelectChange(value, "bcc")}
              onSearch={handleSearch}
              tokenSeparators={[",", ";", " "]}
              notFoundContent={<span>Press enter to add this email</span>}
              onInputKeyDown={(e) => handleKeyPress(e, "bcc")}
              searchValue={searchValue}
            >
              {mergeEmails(fetchedEmails).map((recipient) => (
                <Option key={recipient} value={recipient}>
                  {recipient}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}

      {/* Quill Editor */}
      <div className="text-area-wrapper">
        <ReactQuill
          name="message"
          className="quill-editor"
          theme="snow"
          value={value}
          onChange={handleEditorChange}
          placeholder="Type your message here..."
          modules={{
            toolbar: {
              container: "#quill-toolbar",
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true,
            },
          }}
          // onKeyDown={(event) => {
          //   if (event.key === "Enter") {
          //     if (event.shiftKey) {
          //       return;
          //     }
          //     if (value.trim()) {
          //       event.preventDefault();
          //       handleSend();
          //     } else {
          //       event.preventDefault();
          //     }
          //   }
          // }}
        />
      </div>

      {/* Attachment List */}
      <AttachmentList>
        {selectedFiles.map((file, index) => (
          <AttachmentItem key={index}>
            <TruncateText text={file.name} />
            <span className="file-size">
              ({(file.size / 1024).toFixed(1)}kb)
            </span>
            <RemoveIcon onClick={() => handleRemoveFile(index)}>
              <IoClose />
            </RemoveIcon>
          </AttachmentItem>
        ))}
      </AttachmentList>

      {/* Buttons */}
      <ButtonsWrapper>
        <div id="quill-toolbar" className="custom-toolbar">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-link"></button>
        </div>

        <Button onClick={handleFileClick}>
          <FiPaperclip />
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            multiple
          />
        </Button>

        <button
          onClick={handleSend}
          className="send-button"
          disabled={sendingMail || value === ""}
        >
          {sendingMail ? "Sending" : "Send"}
        </button>
      </ButtonsWrapper>
    </MessageBodyContainer>
  );
};

export default MessageBody;
