import React, { useState, useEffect, useRef } from 'react'
import { Form } from 'antd'
import {
  Banner,
  ComponentsContainer,
  ProductPlansEnableAndContactContainer,
  PlanFormsContainer,
  MainContainer,
  FixedContainer,
} from './styels'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import CommonHaeder from '../../components/header/commonHeader/CommonHaeder'
import useGetData from '../../hooks/useGetData'
import { InstanceWithAuth } from '../../App'
import ReactTostify from '../../common/ReactTostify'
import Spinner from '../../common/Spinner'
import PlansSidebar from '../../components/temporaryLiveAnsweringDetails/plansSidebar/PlansSidebar'
import UserVisibility from '../../components/temporaryLiveAnsweringDetails/userVisibility/UserVisibility'
import DetailForm from '../../components/temporaryLiveAnsweringDetails/detailForm/DetailForm'
import DefaultEndDate from '../../components/temporaryLiveAnsweringDetails/defaultEndDate/DefaultEndDate'
import VoiceMailSettings from '../../components/temporaryLiveAnsweringDetails/voiceMailSettings/VoiceMailSettings'
import { handSelectCompanyFlowTab } from '../../redux/app/appSlice'

const TemporaryLiveAnsweringDetails = () => {
  const { contactId, companyId, profileId, profileNumber } = useParams()
  const [form] = Form.useForm()
  const { app, auth } = useSelector((state) => state)
  const navigate = useNavigate()
  const [profileDetails, setProfileDetails] = useState(null)

  const [preferredAreaCode, setPreferredAreaCode] = useState('')
  const [voicemailOption, setVoicemailOption] = useState('')
  const [status, setStatus] = useState('')
  const [callHandling, setCallHandling] = useState('')
  const [active, setActive] = useState(1)
  const [activeSection, setActiveSection] = useState(1)
  const observer = useRef(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { data: companyData, error } = useGetData(`companies/${companyId}`)

  const getCurrentDate = () => {
    const today = new Date()
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(today.getDate()).padStart(2, '0')}`
  }

  useEffect(() => {
    const fetchNumberDetails = async () => {
      try {
        const response = await InstanceWithAuth.get(
          `live-answering/details/${profileId}`,
          {
            headers: { Authorization: `Bearer ${auth?.userObj?.api_token}` },
          }
        )
        if (response.status === 200 || response.status === 201) {
          setProfileDetails(response.data?.data)
        }
      } catch (error) {
        console.error('Failed to fetch number details', error)
      }
    }

    if (profileId) {
      fetchNumberDetails()
    }
  }, [profileId, auth])

  function normalizePhoneNumber(phoneNumber) {
    const regex = /^\+\d+\s(\+\d+\s.+)/
    const match = phoneNumber.match(regex)
    if (match) {
      return match[1]
    }
    return phoneNumber
  }
  useEffect(() => {
    if (profileDetails) {
      form.setFieldsValue({
        activation_date:
          profileDetails?.phone_number?.activation_date || getCurrentDate(),
        forward_calls_to:
          profileDetails?.phone_number?.forward_calls_to &&
          normalizePhoneNumber(profileDetails?.phone_number?.forward_calls_to),
        virtual_phone_number: profileDetails?.phone_number?.id,
        preferred_area_code: profileDetails?.phone_number?.preferred_area_code,
        voicemail_script: profileDetails?.phone_number?.voicemail_script,
        greeting_company_name: profileDetails?.greeting_company_name,
        notification_email: profileDetails?.notification_email,
        short_business_description: profileDetails?.short_business_description,
        special_instructions: profileDetails?.special_instructions,
        area_code: profileDetails?.preferred_area_code,
        // phone_number_status: profileDetails?.phone_number?.status,
        call_handling: profileDetails?.call_handling_type,
        voicemail_option: profileDetails?.phone_number?.voicemail_option,
      })
      setStatus(profileDetails.status)
      setCallHandling(profileDetails.call_handling_type)
    }
  }, [profileDetails])

  const handleExternalSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setLoading(true)
          const formData = new URLSearchParams()
          // Helper function to check if a value is valid
          const isValid = (value) =>
            value !== undefined && value !== null && value !== ''

          // Add values to formData only if they are valid
          if (isValid(contactId)) {
            formData.append('contact_id', contactId)
          }
          if (isValid(companyId)) {
            formData.append('company_id', companyId)
          }
          if (isValid(values?.virtual_phone_number)) {
            formData.append('phone_number_id', values?.virtual_phone_number)
          }
          if (isValid(values?.activation_date)) {
            formData.append('activation_date', values?.activation_date)
          }
          if (isValid(callHandling)) {
            formData.append('call_handling_type', callHandling)
          }
          if (isValid(values?.forward_calls_to)) {
            formData.append('forward_calls_to', values?.forward_calls_to)
          }
          if (isValid(preferredAreaCode)) {
            formData.append('preferred_area_code', preferredAreaCode)
          }
          if (isValid(values?.greeting_company_name)) {
            formData.append(
              'greeting_company_name',
              values?.greeting_company_name
            )
          }
          if (isValid(values?.notification_email)) {
            formData.append('notification_email', values?.notification_email)
          }
          if (isValid(values?.short_business_description)) {
            formData.append(
              'short_business_description',
              values?.short_business_description
            )
          }
          if (isValid(values?.special_instructions)) {
            formData.append(
              'special_instructions',
              values?.special_instructions
            )
          }
          if (isValid(voicemailOption)) {
            formData.append('voicemail_option', voicemailOption)
          }
          if (isValid(values?.voicemail_script)) {
            formData.append('voicemail_script', values?.voicemail_script)
          }
          const url = `live-answering/${profileId}`

          const response = await InstanceWithAuth.put(url, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          })

          if (response?.status === 201 || response?.status === 200) {
            ReactTostify("success", response?.data?.message);

            dispatch(handSelectCompanyFlowTab('5'))
            navigate(`/companies/${companyId}`)
          }
        } catch (error) {
        ReactTostify("error", error?.response?.data?.message);
        } finally {
          setLoading(false)
        }
      })
      .catch((errorInfo) => {
        console.error('Validation failed:', errorInfo)
      })
  }

  const scrollToSection = (id) => {
    setActive(id)
    const section = document.getElementById(id)
    section.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target
        if (visibleSection) {
          setActiveSection(visibleSection.id)
        }
      },
      {
        rootMargin: '-40% 0px',
        scrollMargin: '-40% 0px',
        delay: 100,
      }
    )

    const sections = document.querySelectorAll('.scroll-section')

    sections.forEach((section) => {
      observer.current.observe(section)
    })

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section)
      })
    }
  }, [])

  const handleSubmit = (value) => {}

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '42px',
      }}
    >
      <CommonHaeder
        firstBcItem={'Companies'}
        firstBcIcon={'companies'}
        firtBcUrl={'/companies'}
        secondBcItem={companyData?.data?.name}
        secondBcIcon="companies"
        secondBcUrl={`/companies/${companyData?.data?.id}`}
        thirdBcItem={'Live Answering Profiles'}
        thirdBcIcon="live answering"
        thirdBcUrl={`/companies/${companyData?.data?.id}`}
        fourthBcItem={`Live Answering Plan #${profileNumber}${
          profileDetails?.phone_number?.virtual_phone_number !== 'Pending'
            ? ` (${profileDetails?.phone_number?.virtual_phone_number})`
            : ''
        }`}
        fourthBcIcon="live answering"
      ></CommonHaeder>
      <MainContainer collapsed={app?.sidebarCollapsed}>
        <Banner>
          <div className="label">
            <div className="back-arrow" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M10 4L6 8L10 12"
                  stroke="#2B3746"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <span>{`Live Answering Plan #${profileNumber} `}</span>
            {/* {profileDetails?.phone_number?.virtual_phone_number && (
              <a
                // href={`tel:${profileDetails.phone_number.virtual_phone_number}`}
                className="blue-link"
                onClick={() =>
                  console.log(
                    "Phone number clicked:",
                    profileDetails.phone_number.virtual_phone_number
                  )
                }
              >
                ({profileDetails.phone_number.virtual_phone_number})
              </a>
            )} */}
          </div>

          <div className="btns">
            <div className="btns">
              <button className="cancel-btn" onClick={() => navigate(-1)}>
                <span className="btn-text">Cancel</span>
              </button>
              <button className="save-btn" onClick={handleExternalSubmit}>
                <span className="btn-text">
                  {loading ? <Spinner></Spinner> : 'Save'}
                </span>
              </button>
            </div>
          </div>
        </Banner>

        <PlanFormsContainer>
          <PlansSidebar
            scrollToSection={scrollToSection}
            active={active}
            activeSection={activeSection}
          />
          <FixedContainer className="fixed">
            <Form
              className="main-form"
              onFinish={handleSubmit}
              form={form}
              requiredMark={false}
            >
              <ProductPlansEnableAndContactContainer>
                <ComponentsContainer>
                  <DetailForm
                    id={1}
                    status={status}
                    setStatus={setStatus}
                    preferredAreaCode={preferredAreaCode}
                    setPreferredAreaCode={setPreferredAreaCode}
                    companyId={companyId}
                  />

                  {/* <DefaultEndDate
                    id={2}
                    contactId={contactId}
                    useLiveAnsweringPlan={useLiveAnsweringPlan}
                    setUseLiveAnsweringPlan={setUseLiveAnsweringPlan}
                    liveAnsweringPlan={liveAnsweringPlan}
                    setLiveAnsweringPlan={setLiveAnsweringPlan}
                  /> */}

                  <UserVisibility
                    id={2}
                    callHandling={callHandling}
                    setCallHandling={setCallHandling}
                  />

                  {/* <VoiceMailSettings
                    id={3}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    voicemailOption={voicemailOption}
                    setVoicemailOption={setVoicemailOption}
                  /> */}
                </ComponentsContainer>
              </ProductPlansEnableAndContactContainer>
            </Form>
          </FixedContainer>
        </PlanFormsContainer>
      </MainContainer>
    </div>
  )
}

export default TemporaryLiveAnsweringDetails
