import React, { useEffect, useRef, useState } from 'react'
import ChatContainer from './styles'
import ChatMessage from '../liveChatMessage/ChatMessage'
import moment from 'moment'
import { Skeleton } from 'antd'
import useGetData from '../../../../hooks/useGetData'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import Comment from '../comment/Comment'
function LiveChat({
  email,
  currentEmployeeImage,
  selectedEmailId,
  setCurrentChatThread,
  onEdit,
  setSelectedTab,
  notes,
  refetchNotes,
  chatThread,
  chatLoading,
  chatLoadingError,
  refetchThread,
}) {
  const chatContainerRef = useRef(null)
  const [isScrolling, setIsScrolling] = useState(false)
  const [messages, setMessages] = useState([])

  const {
    data: allAvailbleConversations,
    error: emailError,
    loading,
    refetchData: refetchEmails,
  } = useGetData('live-chat')

  useEffect(() => {
    if (chatThread) {
      setCurrentChatThread(chatThread)
    }
  }, [chatThread, selectedEmailId])
  useEffect(() => {
    refetchThread()
  }, [selectedEmailId])
  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'pusher',
      key: 'local',
      cluster: 'your-app-cluster',
      wsHost: `${process.env.REACT_APP_SOCKET_BASE_URL}`,
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
      disableStats: true,
    })
    echo.channel(`chat.${selectedEmailId}`).listen('MessageSent', (event) => {
      setMessages((prevMessages) => [...prevMessages, event])

      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight
      }
    })
    echo.connector.pusher.connection.bind('connected', () => {
      console.log('Successfully connected to Pusher')
    })
    return () => {
      echo.disconnect()
    }
  }, [selectedEmailId])
  useEffect(() => {
    if (chatThread) {
      const initialMessages = Object.values(chatThread.data.threads).flat()
      setMessages(initialMessages)
    }
  }, [chatThread, selectedEmailId])
  useEffect(() => {
    const chatContainer = chatContainerRef.current

    const handleScroll = () => {
      setIsScrolling(true)
      clearTimeout(chatContainer.scrollTimeout)
      chatContainer.scrollTimeout = setTimeout(() => {
        setIsScrolling(false)
      }, 1000)
    }

    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [email, chatThread, messages])

  if (chatLoading) {
    return (
      <Skeleton
        rows={10}
        paragraph={{ rows: 7 }}
        active
        style={{ margin: '10px', width: 'auto' }}
      ></Skeleton>
    )
  }

  const formatTime = (time) => {
    return moment(time).format('ddd, MMM D, YYYY')
  }

  const formatTime1 = (time) => {
    return moment(time).format('h:mm A')
  }

  const formatActivityMessage = (activity) => {
    const formattedTime = moment(activity).format('MMM D, YYYY h:mm A')
    return `${activity} on ${formattedTime}`
  }
  const getAllDataSorted = () => {
    const notesData = notes?.data
      .filter((note) => note?.created_at)
      .map((note) => ({
        type: 'note',
        content: note,
        timestamp: note.created_at,
      }))

    const mergedData = [...messages, ...(notesData ? notesData : [])]

    return mergedData.sort((a, b) => {
      const dateA = new Date(
        a.type === 'note' ? a.content.created_at : a.created_at
      )
      const dateB = new Date(
        b.type === 'note' ? b.content.created_at : b.created_at
      )
      return dateA - dateB
    })
  }

  const allData = getAllDataSorted()
  console.log(allData, 'allData in live chat')
  return (
    <ChatContainer
      ref={chatContainerRef}
      className={isScrolling ? 'scrolling' : ''}
    >
      {/* {chatThread?.data?.threads &&
        Object.entries(chatThread?.data?.threads)?.map(([item, index]) => (
          <div key={index}>
            {index?.map((currentThread) => (
              <>
                <ChatMessage
                  key={currentThread?.id}
                  emailData={email?.data}
                  message={currentThread?.message}
                  timestamp={formatTime(currentThread?.timestamp)}
                  time={formatTime1(currentThread?.timestamp)}
                  imageName={'user'}
                  currentEmployeeImage={currentEmployeeImage}
                  attachments={currentThread?.content?.attachments}
                  sender_avatar={currentThread.content?.sender_avatar}
                  type={currentThread?.type}
                  allAvailbleConversations={allAvailbleConversations}
                  currentThread={currentThread}
                />
                <div className="activity">
                  <span>
                    {formatActivityMessage(currentThread?.updated_at)}
                  </span>
                </div>
              </>
            ))}
          </div>
        ))} */}

      {allData?.map((currentThread) => (
        <>
          {currentThread.type === 'note' ? (
            <Comment
              key={currentThread.content.id}
              note={currentThread.content}
              selectedEmailId={selectedEmailId}
              refetchNotes={refetchNotes}
              onEdit={onEdit}
              setSelectedTab={setSelectedTab}
            />
          ) : currentThread.type === 'activity' ? (
            <div className="activity">
              <span>{formatActivityMessage(currentThread?.updated_at)}</span>
            </div>
          ) : (
            <ChatMessage
              key={currentThread?.id}
              emailData={email?.data}
              message={currentThread?.message}
              timestamp={formatTime(currentThread?.timestamp)}
              time={formatTime1(currentThread?.timestamp)}
              imageName={'user'}
              currentEmployeeImage={currentEmployeeImage}
              attachments={currentThread?.attachments}
              sender_avatar={currentThread.sender_avatar}
              type={currentThread?.type}
              allAvailbleConversations={allAvailbleConversations}
              chatThread={chatThread}
            />
          )}
        </>
      ))}
    </ChatContainer>
  )
}

export default LiveChat
