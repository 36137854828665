import React, { useState, useEffect } from "react";
import { FirstColumnContainer } from "./styles";
import GetLogo from "../../getlogo/getlogo";
import ColumnHeader from "./columnHeaderr/ColumnHeader";
import useGetData from "../../../hooks/useGetData";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";

const FirstColumn = ({
  selectedFolder,
  onFolderSelect,
  isAllClosedSelected,
  setRefetchCount,
  openComposer,
  selectedEmailId,
  setSearchParm,
  department,
  setDepartment,
  sidebarCollapsed,
}) => {
  const [menuItems, setMenuItems] = useState([]);
  const { app } = useSelector((state) => state);
  const { data: departments, error } = useGetData("support/departments");
  const { data: count, refetchData: refetchCount } = useGetData(
    `support/emails/count?department_id=${department?.id}`
  );

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (departments && departments.data.length > 0) {
      setDepartment(departments.data[0]);
    }
  }, [departments]);

  useEffect(() => {
    if (setRefetchCount) {
      setRefetchCount(refetchCount);
    }
  }, [refetchCount, setRefetchCount]);

  useEffect(() => {
    if (count) {
      setMenuItems([
        {
          key: "5",
          icon: GetLogo("chat"),
          label: "All Open",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.all,
        },
        {
          key: "1",
          icon: GetLogo("mailbox"),
          label: "Inbox",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.inbox,
        },
        {
          key: "6",
          icon: GetLogo("chat"),
          label: "Chat",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.chat,
        },

        {
          key: "2",
          icon: GetLogo("mailbox"),
          label: "Sent",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.inbox,
        },
        {
          key: "3",
          icon: GetLogo("reports"),
          label: "Spam",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.spam,
        },
        {
          key: "4",
          icon: GetLogo("delete"),
          label: "Trash",
          className: "menu-item",
          isBadge: true,
          count: count?.data?.trash,
        },

        {
          key: "7",
          icon: GetLogo("mailbox"),
          label: "All Closed",
          className: "menu-item",
          isBadge: true,
          // count: count?.data?.closed,
        },
      ]);

      setIsInitialLoad(false);
    }
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchCount();
    }, 5000);

    return () => clearInterval(interval);
  }, [refetchCount]);

  return (
    <FirstColumnContainer>
      <Skeleton
        loading={isInitialLoad && (!departments || !count)}
        active
        style={{ margin: "10px", width: "auto" }}
      >
        <div>
          {app?.sidebarCollapsed && (
            <ColumnHeader
              department={department}
              setDepartment={setDepartment}
              departments={departments?.data}
              setSearchParm={setSearchParm}
            />
          )}

          {menuItems.map((item) => (
            <>
              {!app?.sidebarCollapsed ? (
                <>
                  <div
                    className={`small-sidebar-item ${
                      (selectedFolder === item.label && !isAllClosedSelected) ||
                      (item.label === "All closed" && isAllClosedSelected)
                        ? "active"
                        : ""
                    }`}
                    key={item.key}
                    onClick={() => onFolderSelect(item?.label, item?.key)}
                  >
                    <div className="right">
                      <span className="icon">{item.icon}</span>
                    </div>
                    {(selectedFolder === item.label && !isAllClosedSelected) ||
                    item.label === "All Open" ||
                    item.label === "Chat" ||
                    item.label === "Inbox" ? (
                      <span className="left">{item.count}</span>
                    ) : null}
                  </div>
                </>
              ) : (
                <div
                  className={`sidebar-item ${
                    (selectedFolder === item.label && !isAllClosedSelected) ||
                    (item.label === "All closed" && isAllClosedSelected)
                      ? "active"
                      : ""
                  }`}
                  key={item.key}
                  onClick={() => onFolderSelect(item?.label, item?.key)}
                >
                  <div className="right">
                    <span className="icon">{item.icon}</span>
                    <span className="label">{item.label}</span>
                  </div>
                  {(selectedFolder === item.label && !isAllClosedSelected) ||
                  item.label === "All Open" ||
                  item.label === "Chat" ||
                  item.label === "Inbox" ? (
                    <span className="left">{item.count}</span>
                  ) : null}
                </div>
              )}
            </>
          ))}
        </div>

        {app?.sidebarCollapsed && (
          <>
            {selectedEmailId && (
              <button onClick={openComposer} className="compose-button">
                Compose
              </button>
            )}
          </>
        )}
      </Skeleton>
    </FirstColumnContainer>
  );
};

export default FirstColumn;
