import React, { useState, useRef, useEffect } from "react";
import { MainContainer } from "./styles";
import {
  MdOutlinePersonAddAlt1,
  MdInfoOutline,
  MdBlock,
  MdExitToApp,
  MdDeleteOutline,
} from "react-icons/md";
import { BsPersonGear } from "react-icons/bs";
import { InstanceWithAuth } from "../../../../App";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const SpaceOptionsDropDown = ({
  setManageMembersClick,
  selectedSpace,
  setSelectedSpace,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const dropdownRef = useRef(null);
  const { auth } = useSelector((state) => state);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLeaveSpace = async () => {
    setIsLeaving(true);
    try {
      const response = await InstanceWithAuth.get(
        `internal-chat/spaces/${selectedSpace?.id}/members/leave`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setIsOpen(false);
        setSelectedSpace(null);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error.response?.data?.message,
      });
    } finally {
      setIsLeaving(false);
    }
  };

  const handleDeleteSpace = async () => {
    setIsDeleting(true);
    try {
      const response = await InstanceWithAuth.get(
        `internal-chat/spaces/${selectedSpace?.id}/remove`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setIsOpen(false);
        setSelectedSpace(null);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error.response?.data?.message,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">{selectedSpace?.name}</div>
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#4CBBCB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#556373"
                strokeOpacity="0.2"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
        {isOpen && (
          <div className="dropdown-list">
            {selectedSpace?.logedin_user_is_admin === 1 && (
              <div
                className="item-div"
                onClick={() => {
                  setIsOpen(false);
                  setManageMembersClick(true);
                }}
              >
                <BsPersonGear size={15} color="#556373" />
                <span className="dropdown-item">Manage members</span>
              </div>
            )}

            {/* <div
              className="item-div"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <MdInfoOutline size={15} color="#556373" />
              <span className="dropdown-item">Space details</span>
            </div> */}
            {/* <div
              className="item-div"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <MdBlock size={15} color="#556373" />
              <span className="dropdown-item">Block & Report</span>
            </div> */}
            <div className="item-div" onClick={handleLeaveSpace}>
              <MdExitToApp size={15} color="#556373" />
              <span className="dropdown-item">
                {isLeaving ? "Leaving..." : "Leave"}
              </span>
            </div>
            {selectedSpace?.logedin_user_is_admin === 1 && (
              <div className="item-div" onClick={handleDeleteSpace}>
                <MdDeleteOutline size={15} color="#556373" />
                <span className="dropdown-item">
                  {isDeleting ? "Deleting..." : "Delete"}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </MainContainer>
  );
};

export default SpaceOptionsDropDown;
