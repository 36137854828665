import React, { useState, useEffect } from 'react'
import {
  Header,
  Body,
  HeaderTitle,
  MessageBodyContainer,
  MainConatiner,
} from './styles'
import Swal from 'sweetalert2'
import { Skeleton, Spin } from 'antd'
import useGetData from '../../../../hooks/useGetData'
import { FaExpandAlt, FaMinus, FaSquare } from 'react-icons/fa'
import { CrossIcon } from '../../../../common/customPill/styles'
import Avatar from '../../ThirdColumn/chatHeader/avatar/avatar'
import Chat from './chat/Chat'
import Commets from './commets/Commets'
import ChatInputComponent from '../../contractedChats/chatCard/chatInputComponent/ChatInputComponent'
import { useSelector, useDispatch } from 'react-redux'
import { InstanceWithAuth } from '../../../../App'
import ReactTostify from '../../../../common/ReactTostify'
import { useNavigate } from 'react-router-dom'
import { RiFullscreenLine } from 'react-icons/ri'
import { AiOutlineFullscreen } from 'react-icons/ai'
import { FaRegSquareFull } from 'react-icons/fa6'
import { handleRefetchMailThread } from '../../../../redux/app/appSlice'
import {
  handleAddCurrentActiveChatThread,
  handleAddCurrentActiveChatTab,
  handleShowContractedChat,
} from '../../../../redux/app/appSlice'
import LiveChartCard from './chat/LiveChartCard'
import Echo from 'laravel-echo'
const LiveChatCards = ({ emailId, removeEmailFromList }) => {
  const [selectedTab, setSelectedTab] = useState('Email')
  const { auth, app } = useSelector((state) => state)
  const [savingComment, setSavingComment] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const [sendingMail, setSendingMail] = useState(false)
  const [editingNote, setEditingNote] = useState(null)
  const [currentChatThread, setCurrentChatThread] = useState()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [messages, setMessages] = useState([])
  const {
    data: chatThread,
    loading: chatLoading,
    error: chatLoadingError,
    refetchData: refetchThread,
  } = useGetData(`live-chat/${emailId}`)
  const {
    data: allAvailbleConversations,
    error: emailError,
    loading,
    refetchData: refetchEmails,
  } = useGetData('live-chat')
  const { data: notes, refetchData: refetchNotes } = useGetData(
    `support/emails/notes/${emailId}`
  )
  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'pusher',
      key: 'local',
      cluster: 'your-app-cluster',
      wsHost: `${process.env.REACT_APP_SOCKET_BASE_URL}`,
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
      disableStats: true,
    })
    echo.channel(`chat.${emailId}`).listen('MessageSent', (event) => {
      console.log('New event received:', event)

      setMessages((prevMessages) => [...prevMessages, event])
    })
    echo.connector.pusher.connection.bind('connected', () => {
      console.log('Successfully connected to Pusher')
    })
    return () => {
      echo.disconnect()
    }
  }, [emailId])
  useEffect(() => {
    if (chatThread) {
      setCurrentChatThread(chatThread)
      const initialMessages = Object.values(chatThread.data.threads).flat()
      setMessages(initialMessages)
    }
  }, [chatThread, emailId])
  // if (error) {
  //   Swal.fire({
  //     title: "Error",
  //     text: `Failed to load email ID ${emailId}`,
  //     icon: "error",
  //     confirmButtonText: "Ok",
  //   });
  //   return null;
  // }

  const handleCloseContractedChat = () => {
    dispatch(handleAddCurrentActiveChatThread(''))
    dispatch(handleAddCurrentActiveChatTab(''))
    dispatch(handleShowContractedChat(false))
  }

  console.log(emailId, 'emailId in chatss')
  useEffect(() => {
    refetchNotes()
  }, [app?.isEmailThreadUpdated])

  const handleRefetcThreads = () => {
    dispatch(handleRefetchMailThread(!app?.isEmailThreadUpdated))
  }
  const TruncateText = ({ text }) => {
    const maxLength = 25
    let truncatedText = text.substring(0, maxLength)
    if (truncatedText.length < text.length) {
      truncatedText += '...'
    }
    return <span title={text}>{truncatedText}</span>
  }

  const handleLiveChatSend = async (data) => {
    setSendingMail(true)
    const { message, files } = data
    const formData = new FormData()

    formData.append('message', message)
    const mostRecentThread = currentChatThread?.data?.threads
      ? Object.entries(currentChatThread.data.threads)[0][1]
      : []
    if (mostRecentThread.length > 0) {
      const firstItem = mostRecentThread[0]
      formData.append('live_chat_id', firstItem.live_chat_id)
      formData.append('user_id', firstItem?.user_id)
    }

    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name)
    })

    try {
      const response = await InstanceWithAuth.post(
        `live-chat/reply-to-chat`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      )
      if (response.status === 201 || response.status === 200) {
        // ReactTostify("success", response?.data?.message);
        // refetchData()
        handleRefetcThreads()
      }
    } catch (error) {
    } finally {
      setSendingMail(false)
    }
  }

  const handleCommentSend = async (data) => {
    setSavingComment(true)
    const { comment, files } = data
    const formData = new FormData()
    // formData.append(
    //   'from_name',
    //   `${email?.data?.contact?.first_name} ${email?.data?.contact?.last_name}`
    // )

    const mostRecentThread = currentChatThread?.data?.threads
      ? Object.entries(currentChatThread.data.threads)[0][1]
      : []
    if (mostRecentThread.length > 0) {
      const firstItem = mostRecentThread[0]
      formData.append(
        'from_name',
        `${firstItem?.first_name} ${firstItem?.last_name}`
      )
    }
    formData.append('notes', comment)

    // if (editingNote) {
    //   formData.append(`attachments[]`, "");
    // } else {
    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name)
    })
    // }

    try {
      let response
      if (editingNote) {
        response = await InstanceWithAuth.post(
          `support/emails/notes/${editingNote.id}/update`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        )
      } else {
        response = await InstanceWithAuth.post(
          `support/emails/notes/${emailId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        )
      }

      if (response.status === 201 || response.status === 200) {
        ReactTostify('success', response?.data?.message)
        handleRefetcThreads()
      }
    } catch (error) {
    } finally {
      setSavingComment(false)
      setEditingNote(null)
    }
  }

  const handleEditNote = (note) => {
    setEditingNote(note)
  }

  let currentThreadOwner = allAvailbleConversations?.data?.filter(
    (chat) => chat?.id == emailId
  )?.[0]
  let Msgsender =
    currentThreadOwner?.company_id != null
      ? currentThreadOwner?.company_name
      : currentThreadOwner?.contact_id != null
      ? currentThreadOwner?.contact_name
      : currentThreadOwner?.guest_name

  return (
    <MainConatiner style={{ height: isVisible ? '500px' : 'auto' }}>
      <Header>
        <div className="make-row">
          <Avatar currentEmployeeImage={''} />
          <HeaderTitle>
            <TruncateText text={Msgsender || ''} />
          </HeaderTitle>
        </div>
        <div className="make-row">
          {isVisible ? (
            <FaMinus
              style={{ cursor: 'pointer', marginRight: '5px' }}
              size={16}
              onClick={() => setIsVisible(false)}
            />
          ) : (
            <FaRegSquareFull
              style={{ cursor: 'pointer', marginRight: '5px' }}
              size={16}
              onClick={() => setIsVisible(true)}
            />
          )}
          <FaExpandAlt
            style={{ cursor: 'pointer' }}
            size={16}
            onClick={() => {
              handleCloseContractedChat()
              navigate(`/support`)
            }}
          />
          <CrossIcon
            size={17}
            style={{ cursor: 'pointer', marginLeft: '1px' }}
            onClick={handleCloseContractedChat}
          />
        </div>
      </Header>
      {isVisible && (
        <>
          <>
            <Body>
              {chatLoading ? (
                <div style={{ width: '330px', padding: '10px' }}>
                  <Skeleton active />
                </div>
              ) : (
                <>
                  {selectedTab === 'Email' ? (
                    <LiveChartCard
                      email={messages}
                      allAvailbleConversations={allAvailbleConversations}
                      //   currentEmployeeImage={email?.data?.contact}
                    />
                  ) : (
                    <Commets
                      selectedEmailId={emailId}
                      refetchNotes={refetchNotes}
                      notes={notes}
                      onEdit={handleEditNote}
                    />
                  )}
                </>
              )}
            </Body>

            <ChatInputComponent
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onSend={handleLiveChatSend}
              handleCommentSend={handleCommentSend}
              savingComment={savingComment}
              email={chatThread}
              sendingMail={sendingMail}
              notes={notes}
              editingNote={editingNote}
              loading={chatLoading}
              isLiveChat={true}
            />
          </>
        </>
      )}
    </MainConatiner>
  )
}

export default LiveChatCards
