import React, { useEffect, useRef, useState } from "react";
import {
  ComposerWrapper,
  Button1,
  Header,
  Body,
  HeaderTitle,
  MessageBodyContainer,
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
} from "./styles";
import { IoClose } from "react-icons/io5";
import "react-quill/dist/quill.snow.css";
import { FaExpandAlt } from "react-icons/fa";
import { ImShrink2 } from "react-icons/im";
import { FiPaperclip } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { Input, Select } from "antd";
import { Quill } from "react-quill";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { CrossIcon } from "../../../../common/customPill/styles";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import useGetData from "../../../../hooks/useGetData";
import Swal from "sweetalert2";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ReactTostify from "../../../../common/ReactTostify";

Quill.register("modules/emoji", Emoji);

const { Option } = Select;

const EmailComposer = ({
  visible,
  onClose,
  selectedEmailId,
  refetchEmails,
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [toRecipients, setToRecipients] = useState([]);
  const [ccRecipients, setCcRecipients] = useState([]);
  const [bccRecipients, setBccRecipients] = useState([]);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [fetchedEmails, setFetchedEmails] = useState([]);
  const [sendingMail, setSendingMail] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { auth } = useSelector((state) => state);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const refetchData = async () => {
    if (!selectedEmailId) return;
    setLoading(true);
    try {
      const response = await InstanceWithAuth.get(
        `support/emails/${selectedEmailId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setEmail(response.data);
      }
    } catch (err) {
      setError(err);
      console.error("Failed to fetch email data", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refetchData();
  }, [selectedEmailId]);

  const handleFileSelect = (files) => {
    setSelectedFiles(files);
  };

  const handleInputChange = (name, value) => {
    if (name === "message") setMessage(value);
    if (name === "subject") setSubject(value);
  };

  const handleSend = async () => {
    const cleanedMessage = message.replace(/<p>(\s|<br>)*<\/p>$/, "").trim();

    setSendingMail(true);
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("tick", cleanedMessage);

    toRecipients.forEach((recipient, index) =>
      formData.append(`to[${index}]`, recipient)
    );
    ccRecipients.forEach((recipient, index) =>
      formData.append(`cc[${index}]`, recipient)
    );
    bccRecipients.forEach((recipient, index) =>
      formData.append(`bcc[${index}]`, recipient)
    );

    Array.from(selectedFiles).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/compose`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        refetchEmails();
        ReactTostify("success", response?.data?.message);

        // Swal.fire({
        //   icon: "success",
        //   title: "Success!",
        //   text: response?.data?.message,
        //   customClass: {
        //     confirmButton: "custom-swal-button",
        //   },
        // });
        // Reset fields
        setToRecipients([]);
        setCcRecipients([]);
        setBccRecipients([]);
        setSubject("");
        setMessage("");
        setSelectedFiles([]);
        setShowCc(false);
        setShowBcc(false);
        onClose();
      }
    } catch (error) {
      ReactTostify("error", error?.response?.data?.message);
    } finally {
      setSendingMail(false);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    handleFileSelect(files);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const handleEditorChange = (content) => {
    setMessage(content);
    handleInputChange("message", content);
  };

  const handleKeyPress = (event, type) => {
    const emailRegex = /\S+@\S+\.\S+/;
    const inputValue = event.target.value.trim();

    if (event.key === "Enter" && emailRegex.test(inputValue)) {
      // Check which type of recipient to update
      const addEmail = (list, setList) => {
        if (!list.includes(inputValue)) {
          setList([...list, inputValue]);
        }
      };

      if (type === "to") addEmail(toRecipients, setToRecipients);
      if (type === "cc") addEmail(ccRecipients, setCcRecipients);
      if (type === "bcc") addEmail(bccRecipients, setBccRecipients);

      // Clear the input field
      setSearchValue("");
      setTimeout(() => {
        event.target.value = "";
      }, 100);
    }
  };

  const extractEmails = (formattedRecipients) => {
    // Use a regular expression to extract emails from the formatted string
    return formattedRecipients.map((recipient) => {
      const match = recipient.match(
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
      );
      return match ? match[0] : recipient; // Return the email if found, else fallback to the string
    });
  };

  const handleSelectChange = (value, type) => {
    setSearchValue("");
    const emailsOnly = extractEmails(value);
    switch (type) {
      case "to":
        setToRecipients(emailsOnly);
        break;
      case "cc":
        setCcRecipients(emailsOnly);
        break;
      case "bcc":
        setBccRecipients(emailsOnly);
        break;
      default:
        break;
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleSearch = async (searchTerm) => {
    setSearchValue(searchTerm);
    if (!searchTerm) return;

    if (searchTerm === "") {
      setFetchedEmails([]);
    }

    const formData = new FormData();
    formData.append("search", searchTerm);

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/suggestion_emails`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.data?.status === "success") {
        setFetchedEmails(response?.data?.data?.filter(Boolean));
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  const mergeEmails = () => {
    // Extract sender's name details
    const senderContact = email?.data?.contact;
    const senderEmail = email?.data?.sender_email;
    const senderName = senderContact
      ? `${senderContact.first_name || ""} ${
          senderContact.last_name || ""
        }`.trim()
      : email?.data?.sender_name || "";
    const senderCompanyName = senderContact?.company_name || "";

    // Format sender name with email and company
    const formattedSender = senderName
      ? `${senderName} (${senderEmail})${
          senderCompanyName ? ` (${senderCompanyName})` : ""
        }`
      : `${senderEmail}${senderCompanyName ? ` (${senderCompanyName})` : ""}`;

    // Format fetched emails
    const formattedFetchedEmails = fetchedEmails.map((contact) => {
      const { first_name, last_name, email, company_name } = contact;

      // Build the name part
      const namePart =
        first_name && last_name ? `${first_name} ${last_name}` : "";

      // Combine name, email, and company name
      return namePart
        ? `${namePart} (${email})${company_name ? ` (${company_name})` : ""}`
        : `${email}${company_name ? ` (${company_name})` : ""}`;
    });

    console.log("formattedFetchedEmails", formattedFetchedEmails);

    // Merge sender and formatted fetched emails, removing duplicates
    const mergedEmails = [
      ...new Set([formattedSender, ...formattedFetchedEmails]),
    ].filter(Boolean);
    console.log("mergedEmails", formattedFetchedEmails);

    return mergedEmails;
  };

  const TruncateText = ({ text }) => {
    const maxLength = 20;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  if (!visible) return null;

  const toggleFullScreen = () => {
    setFullScreen((prev) => !prev);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: "1000",
        overflow: "hidden",
        bottom: 0,
        right: 0,
        // background: "rgba(0, 0, 0, 0.5)",
      }}
    >
      {email && (
        <ComposerWrapper fullScreen={fullScreen}>
          <Header className="draggable-handle">
            <HeaderTitle>Email</HeaderTitle>
            <div className="make-row">
              <Button1 onClick={toggleFullScreen}>
                {fullScreen ? <ImShrink2 /> : <FaExpandAlt />}
              </Button1>
              <Button1 onClick={onClose}>
                <CrossIcon />
              </Button1>
            </div>
          </Header>
          <Body>
            <MessageBodyContainer>
              <div className="main-div">
                <div className="left-div">
                  <span className="name">To</span>
                  <Select
                    mode="multiple"
                    suffixIcon={
                      <MdOutlineKeyboardArrowDown
                        size={19}
                        fill="rgba(85, 99, 115, 0.6)"
                      ></MdOutlineKeyboardArrowDown>
                    }
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Enter or choose recipients"
                    value={toRecipients}
                    onChange={(value) => handleSelectChange(value, "to")}
                    onSearch={handleSearch}
                    tokenSeparators={[",", ";", " "]}
                    notFoundContent={<span>Press enter to add this email</span>}
                    onInputKeyDown={(e) => handleKeyPress(e, "to")}
                    searchValue={searchValue}
                  >
                    {mergeEmails(fetchedEmails).map((recipient) => (
                      <Option key={recipient} value={recipient}>
                        {recipient}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="right-div">
                  <span className="name" onClick={() => setShowCc(!showCc)}>
                    Cc
                  </span>
                  <span className="name" onClick={() => setShowBcc(!showBcc)}>
                    Bcc
                  </span>
                </div>
              </div>

              {/* Cc Recipients Field */}
              {showCc && (
                <div className="main-div">
                  <div className="left-div">
                    <span className="name">Cc</span>
                    <Select
                      mode="multiple"
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown
                          size={19}
                          fill="rgba(85, 99, 115, 0.6)"
                        ></MdOutlineKeyboardArrowDown>
                      }
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Enter or choose Cc recipients"
                      value={ccRecipients}
                      onChange={(value) => handleSelectChange(value, "cc")}
                      onSearch={handleSearch}
                      tokenSeparators={[",", ";", " "]}
                      notFoundContent={
                        <span>Press enter to add this email</span>
                      }
                      onInputKeyDown={(e) => handleKeyPress(e, "cc")}
                      searchValue={searchValue}
                    >
                      {mergeEmails(fetchedEmails).map((recipient) => (
                        <Option key={recipient} value={recipient}>
                          {recipient}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}

              {/* Bcc Recipients Field */}
              {showBcc && (
                <div className="main-div">
                  <div className="left-div">
                    <span className="name">Bcc</span>
                    <Select
                      mode="multiple"
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown
                          size={19}
                          fill="rgba(85, 99, 115, 0.6)"
                        ></MdOutlineKeyboardArrowDown>
                      }
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Enter or choose Bcc recipients"
                      value={bccRecipients}
                      onChange={(value) => handleSelectChange(value, "bcc")}
                      onSearch={handleSearch}
                      tokenSeparators={[",", ";", " "]}
                      notFoundContent={
                        <span>Press enter to add this email</span>
                      }
                      onInputKeyDown={(e) => handleKeyPress(e, "bcc")}
                      searchValue={searchValue}
                    >
                      {mergeEmails(fetchedEmails).map((recipient) => (
                        <Option key={recipient} value={recipient}>
                          {recipient}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}

              <div className="main-div">
                <div className="left-div">
                  <span className="name">Subject</span>
                  <Input
                    mode="multiple"
                    allowClear
                    value={subject}
                    type="text"
                    style={{ width: "100%" }}
                    placeholder="Enter subject"
                    className="input"
                    onChange={(e) =>
                      handleInputChange("subject", e.target.value)
                    }
                  ></Input>
                </div>
              </div>

              {/* Quill Editor */}
              <div className="text-area-wrapper">
                <ReactQuill
                  name="message"
                  className="quill-editor"
                  theme="snow"
                  value={message}
                  onChange={handleEditorChange}
                  placeholder="Type your message here..."
                  modules={{
                    toolbar: {
                      container: "#quill-toolbarid",
                      "emoji-toolbar": true,
                      "emoji-textarea": true,
                      "emoji-shortname": true,
                    },
                  }}
                  // onKeyDown={(event) => {
                  //   if (event.key === "Enter") {
                  //     if (event.shiftKey) {
                  //       return;
                  //     }
                  //     if (message.trim()) {
                  //       event.preventDefault();
                  //       handleSend();
                  //     } else {
                  //       event.preventDefault();
                  //     }
                  //   }
                  // }}
                />
              </div>

              {/* Attachment List */}
              <AttachmentList>
                {selectedFiles.map((file, index) => (
                  <AttachmentItem key={index}>
                    <TruncateText text={file.name} />
                    <span className="file-size">
                      ({(file.size / 1024).toFixed(1)}kb)
                    </span>
                    <RemoveIcon onClick={() => handleRemoveFile(index)}>
                      <IoClose />
                    </RemoveIcon>
                  </AttachmentItem>
                ))}
              </AttachmentList>

              {/* Buttons */}
              <ButtonsWrapper>
                <div id="quill-toolbarid" className="custom-toolbar">
                  <button className="ql-bold"></button>
                  <button className="ql-italic"></button>
                  <button className="ql-underline"></button>
                  <button className="ql-list" value="ordered"></button>
                  <button className="ql-list" value="bullet"></button>
                  <button className="ql-link"></button>
                </div>

                <Button onClick={handleFileClick}>
                  <FiPaperclip />
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    multiple
                  />
                </Button>
                <Button
                  className="send-button"
                  onClick={handleSend}
                  disabled={sendingMail || message === ""}
                >
                  {sendingMail ? "Sending..." : "Send"}
                </Button>
              </ButtonsWrapper>
            </MessageBodyContainer>
          </Body>
        </ComposerWrapper>
      )}
    </div>
  );
};

export default EmailComposer;
