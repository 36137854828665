import React, { useState, useEffect, useCallback } from "react";
import { FiltersCoulumnContainer } from "./styles";
import { IoChevronBackSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddOpenConversationDetails,
  handleSupportSearchShow,
} from "../../../../redux/app/appSlice";
import { Select, Form, DatePicker } from "antd";
import { InstanceWithAuth } from "../../../../App";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import moment from "moment";

const FiltersCoulumn = ({
  form,
  filters,
  setFilters,
  setSearchTerms,
  setSelectedEmailOrChat,
  setSkipFetch,
  setEmails,
}) => {
  const { auth, app } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [perPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalContacts, setTotalContacts] = useState(0);
  const [contact, setContact] = useState(null);
  const [employees, setEmployees] = useState([]);

  // Handle form changes and format dates properly
  const handleFormItemChange = (changedValues) => {
    const formattedValues = Object.entries(changedValues).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]:
          value && moment.isMoment(value) ? value.format("YYYY-MM-DD") : value,
      }),
      {}
    );

    setFilters((prev) => ({
      ...prev,
      ...formattedValues,
    }));
  };

  const handleConatctChange = (value) => {
    const intValue = parseInt(value);
    setContact(intValue);
  };

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const response = await InstanceWithAuth.get(
        `contacts?query=${searchTerm}&per_page=${perPage}&page=${currentPage}&order=DESC`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        const { data, total } = response.data;
        setContacts(data);
        setTotalContacts(total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [currentPage, searchTerm]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setCurrentPage(1);
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const fetchEmployee = async () => {
    setLoading(true);
    try {
      const response = await InstanceWithAuth.get(`users`, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        const { data } = response.data;
        setEmployees(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  const handleClearFilters = () => {
    const initialFilters = {
      status: null,
      contact: null,
      assignee: null,
      startDate: null,
      endDate: null,
    };
    form.resetFields();
    setSkipFetch(true);
    setFilters(initialFilters);
    setSearchTerms("");
    setSelectedEmailOrChat(null);
    setEmails(null);
  };

  return (
    <FiltersCoulumnContainer>
      <div
        className="exit-btn"
        onClick={() => {
          dispatch(handleSupportSearchShow());
          dispatch(handleAddOpenConversationDetails(null));
        }}
      >
        <IoChevronBackSharp color="#4cbbcb" />
        <span>Exit search</span>
      </div>
      <div className="filter-by">
        <span className="heading">FILTER BY:</span>
        <span className="clear-btn" onClick={handleClearFilters}>
          Clear all
        </span>
      </div>
      <div className="form-container">
        <div className="form-main">
          <div className="form">
            <Form
              form={form}
              onValuesChange={handleFormItemChange}
              initialValues={filters}
            >
              <Form.Item label="Status" name="status" className="form-item">
                <Select
                  showSearch
                  placeholder="Select a status"
                  className="location"
                  // onChange={handleSelectChange}
                  options={[
                    {
                      label: "Open",
                      value: 0,
                    },
                    {
                      label: "Closed",
                      value: 1,
                    },
                  ]}
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main">
          <div className="form">
            <Form form={form} onValuesChange={handleFormItemChange}>
              <Form.Item label="Conatct" name="contact" className="form-item">
                <Select
                  showSearch
                  placeholder="Select a contact"
                  className="location"
                  onSearch={handleSearchChange}
                  onChange={handleConatctChange}
                  options={contacts.map((contact) => ({
                    label: `${contact.first_name} ${contact.last_name}`,
                    value: contact.id,
                  }))}
                  loading={loading}
                  filterOption={false}
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main">
          <div className="form">
            <Form form={form} onValuesChange={handleFormItemChange}>
              <Form.Item label="Assignee" name="assignee" className="form-item">
                <Select
                  showSearch
                  placeholder="Select an assignee"
                  className="location"
                  // onChange={handleSelectChange}
                  options={employees.map((employee) => ({
                    label: `${employee.first_name} ${employee.last_name}`,
                    value: employee.id,
                  }))}
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main">
          <div className="form">
            <Form form={form} onValuesChange={handleFormItemChange}>
              <Form.Item
                label="Start Date"
                name="startDate"
                className="form-item"
              >
                <DatePicker
                  placeholder="Start Date"
                  suffixIcon={null}
                  className="datePicker"
                  // onChange={handleStartDateChange}
                />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="form-main">
          <div className="form">
            <Form form={form} onValuesChange={handleFormItemChange}>
              <Form.Item label="End Date" name="endDate" className="form-item">
                <DatePicker
                  placeholder="End Date"
                  suffixIcon={null}
                  className="datePicker"
                  // onChange={handleStartDateChange}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </FiltersCoulumnContainer>
  );
};

export default FiltersCoulumn;
