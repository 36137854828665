import React, { useState } from "react";
import { Container, ContentContainer, DrawerContainer } from "./styles";
import UserView from "./userDetailComponents/userView/UserView";
import Account from "./userDetailComponents/account/Account";
import Companies from "./userDetailComponents/companies/Companies";
import Mailbox from "./userDetailComponents/mailbox/Mailbox";
import Payment from "./userDetailComponents/payment/Payment";
import Invoices from "./userDetailComponents/invoices/Invoices";
import Activity from "./userDetailComponents/activity/Activity";
import Support from "./userDetailComponents/support/Support";
import PhoneNumberlist from "./userDetailComponents/phone/PhoneNumberlist";
import LiveAnswering from "./userDetailComponents/liveAnswering/LiveAnswering";
import { InstanceWithAuth } from "../../../App";
import ReactTostify from "../../../common/ReactTostify";
import Swal from "sweetalert2";
import Spinner from "../../../common/Spinner";
import { useSelector } from "react-redux";
import { Form } from "antd";
import Ticket from "../../componyFlowComponents/companyDetailSidebar/companyDetailComponents/ticket/Ticket";
import _ from "lodash";
import omit from "lodash/omit";
import useGetData from "../../../hooks/useGetData";
const UserDetailSidebar = ({
  open,
  setOpen,
  userID,
  fetchAllContacts,
  fetchUserDetails,
  setSideBarOpen,
  hanleSearchContact,
  canHide,
  supportHeader,
  additionalTabs,
  selectedEmailId,
  setClickedCompany,
  calllingFromTicketPage,
}) => {
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [enableSaveBtn, setEnableBtn] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const { auth } = useSelector((state) => state);
  const [form] = Form.useForm();
  const [primaryEmail, setPrimaryEmail] = useState();
  const [dashboardEmail, setDashboardEmailEmail] = useState();
  const [initialValues, setInitialValues] = useState({});
  const {
    data: userData,
    error,
    refetchData: refetchUserData,
  } = useGetData(`contacts/${userID}`);
  const handleUpdateConatct = async (formData) => {
    try {
      setIsLoading(true);
      const response = await InstanceWithAuth.post(
        `contacts/${userID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        if (!calllingFromTicketPage) {
          fetchAllContacts("");
          fetchUserDetails(userID);
          refetchUserData();
        }
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
        setEnableBtn(false);
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
      setEnableBtn(false);
    }
  };
  const handleUpdateEmail = async (emailType) => {
    let ApiData = {
      email: emailType == "primary" ? primaryEmail : dashboardEmail,
    };
    try {
      let response = await InstanceWithAuth.put(`contacts/${userID}`, ApiData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });
      if (response.status === 201 || response.status === 200) {
        ReactTostify("success", response?.data?.message);
      }
    } catch (error) {
      ReactTostify("error", error?.response?.data?.message);
    }
  };
  const handleExternalSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let formData = {
          first_name: values?.firstName,
          last_name: values?.lastName,
          address: "test Adress",
          email: values?.primaryEmail,
          avatar: "",
          phone: values?.number,
        };

        handleUpdateConatct(formData);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleCheckUnchanged = () => {
    const initialValues = form.getFieldsValue();
    const currentValues = form.getFieldsValue(true);

    const unchangedValues = {};
    for (const key in initialValues) {
      if (initialValues.hasOwnProperty(key)) {
        if (initialValues[key] === currentValues[key]) {
          unchangedValues[key] = initialValues[key];
        }
      }
    }
  };

  const handleFormItemChange = (changedValues, allValues) => {
    const filteredInitialValues = _.omit(initialValues);
    const filteredAllValues = _.omit(allValues);
    const isChanged = !_.isEqual(filteredInitialValues, filteredAllValues);
    setEnableBtn(isChanged);
    setShowSaveBtn(isChanged);
  };

  const handleCancel = () => {
    form.setFieldsValue({
      firstName: userData.data?.first_name,
      lastName: userData.data?.last_name,
      number: userData.data?.phone,
      primaryEmail: userData.data.email,
    });
    setShowSaveBtn(false);
  };

  return (
    <DrawerContainer open={open}>
      <Container>
        {supportHeader ? null : (
          <UserView
            setOpen={setOpen}
            userID={userID}
            fetchUserDetails={fetchUserDetails}
            setSideBarOpen={setSideBarOpen}
            hanleSearchContact={hanleSearchContact}
            canHide={canHide}
          />
        )}
        <ContentContainer>
          <Account
            userID={userID}
            form={form}
            handleFormItemChange={handleFormItemChange}
            setPrimaryEmail={setPrimaryEmail}
            setDashboardEmailEmail={setDashboardEmailEmail}
            handleUpdateEmail={handleUpdateEmail}
            setShowSaveBtn={setShowSaveBtn}
            fetchUserDetails={fetchUserDetails}
            handleCheckUnchanged={handleCheckUnchanged}
            formUpdatingloading={loading}
            setInitialValues={setInitialValues}
            userData={userData}
            refetchUserData={refetchUserData}
          />
          {additionalTabs && (
            <Ticket userID={userID} selectedEmailId={selectedEmailId} />
          )}
          <Companies userID={userID} setClickedCompany={setClickedCompany} />
          <Mailbox userID={userID} userData={userData} />
          <PhoneNumberlist userID={userID} userData={userData} />
          <LiveAnswering userID={userID} userData={userData} />
          <Payment userId={userID} />
          <Invoices userID={userID} />
          <Activity userID={userID} />
          <Support userID={userID} />
          {showSaveBtn && (
            <div
              className="save-btn"
              style={{
                position: "fixed",
              }}
            >
              <button className="btn cancel-btn" onClick={() => handleCancel()}>
                cancel
              </button>
              <button
                disabled={!enableSaveBtn ? true : false}
                className={
                  enableSaveBtn ? "save-btn btn" : "btn save-btn-disabled"
                }
                onClick={handleExternalSubmit}
              >
                {loading ? <Spinner></Spinner> : "Save"}
              </button>{" "}
            </div>
          )}
        </ContentContainer>
      </Container>
    </DrawerContainer>
  );
};

export default UserDetailSidebar;
