import React, { useState, useRef, useEffect } from "react";
import { MainContainer } from "./styles";
import EmptyChat from "./emptyChat/EmptyChat";
import ChatInput from "./chatInput/ChatInput";
import AddMembersModel from "./addMembersModel/AddMembersModel";
import ChatMessages from "./ChatMessages/ChatMessages";
import MessageThread from "./messageThead/MessageThead";
import { RxCross2 } from "react-icons/rx";
import { Skeleton } from "antd";
import { InstanceWithAuth } from "../../../../App";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ThreadInput from "./threadInput/ThreadInput";
import useGetData from "../../../../hooks/useGetData";

const ChatSection = ({
  spaceMessages,
  loadingMessages,
  selectedSpace,
  refetchSpaceMessages,
  quotedMessage,
  setQuotedMessage,
  threadQuotedMessage,
  setThreadQuotedMessage,
  firstRender,
  setFirstRender,
  openThread,
  setOpenThread,
  mainDivWidth,
  setMainDivWidth,
}) => {
  const [isModelOpen, setIsPipelineModelOpen] = useState(false);
  const [value, setValue] = useState("");
  const [threadValue, setThreadValue] = useState("");
  const { auth } = useSelector((state) => state);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [sendingMessageInThread, setSendingMessageInThread] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [threadAttachments, setThreadAttachments] = useState([]);
  const [threadSelectedFiles, setThreadSelectedFiles] = useState([]);

  const {
    data: threadMessages,
    threadMessagesError,
    refetchData: refetchThreadMessages,
    loading: loadingThreadMessages,
  } = useGetData(
    `internal-chat/spaces/${selectedSpace?.id}/messages/${openThread}`
  );

  const containerRef = useRef(null);
  const chatDivRef = useRef(null);

  // useEffect(() => {
  //   if (chatDivRef.current) {
  //     chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
  //   }
  // }, [spaceMessages]);

  useEffect(() => {
    if (chatDivRef.current) {
      if (firstRender || (scrollToBottom && spaceMessages)) {
        chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
        setFirstRender(false);
        setScrollToBottom(false);
      } else {
        const isAtBottom =
          chatDivRef.current.scrollHeight - chatDivRef.current.scrollTop <=
          chatDivRef.current.clientHeight + 50;

        if (isAtBottom) {
          chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
        }
      }
    }
  }, [spaceMessages]);

  const toggleThread = (id) => {
    setOpenThread(openThread === id ? null : id);
    setMainDivWidth(mainDivWidth === 100 ? 60 : mainDivWidth);
  };

  const closeThread = () => {
    setOpenThread(null);
    setMainDivWidth(100);
  };

  const handleOk = async () => {
    setIsPipelineModelOpen(false);
  };

  const handleCancel = () => {
    setIsPipelineModelOpen(false);
  };

  const showModal = () => {
    setIsPipelineModelOpen(true);
  };

  // Handle the drag for resizing
  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startWidth = containerRef.current.offsetWidth;
    const handleMouseMove = (moveEvent) => {
      const newWidth =
        ((startWidth + moveEvent.clientX - startX) / window.innerWidth) * 100;
      if (newWidth > 30 && newWidth < 80) {
        setMainDivWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleSendMessage = async (message, files) => {
    setSendingMessage(true);
    setQuotedMessage(null);
    const formData = new FormData();
    formData.append("message", message || "");
    formData.append("replied_to", quotedMessage ? quotedMessage?.id : "");
    if (files && files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`attachments[${index}]`, file, file.name);
      });
    }

    try {
      const response = await InstanceWithAuth.post(
        `internal-chat/spaces/${selectedSpace?.id}/add-message`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.status === 201) {
        setValue("");
        // refetchSpaceMessages();
        setSelectedFiles([]);
        setSendingMessage(false);
        setQuotedMessage(null);
        await refetchSpaceMessages();
        setScrollToBottom(true);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
      setSendingMessage(false);
    }
  };

  const handleSendMessageInThread = async (message, files) => {
    setSendingMessageInThread(true);
    setThreadQuotedMessage(null);
    const formData = new FormData();
    formData.append("message", message || "");
    formData.append(
      "replied_to",
      threadQuotedMessage ? threadQuotedMessage?.id : ""
    );

    if (files && files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`attachments[${index}]`, file, file.name);
      });
    }

    try {
      const response = await InstanceWithAuth.post(
        `internal-chat/spaces/${selectedSpace?.id}/messages/${openThread}/add-detail`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.status === 201) {
        setThreadValue("");
        setThreadSelectedFiles([]);
        refetchThreadMessages();
        setSendingMessageInThread(false);
        setThreadQuotedMessage(null);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
      setSendingMessageInThread(false);
    }
  };

  return (
    <MainContainer mainDivWidth={mainDivWidth} ref={containerRef}>
      <div className="main-div">
        <div className="chat-div" ref={chatDivRef}>
          {loadingMessages && firstRender ? (
            <>
              <Skeleton className="skelton-wrapper" active />
            </>
          ) : (
            <ChatMessages
              openThread={openThread}
              setOpenThread={setOpenThread}
              toggleThread={toggleThread}
              setQuotedMessage={setQuotedMessage}
              spaceMessages={spaceMessages}
              refetchSpaceMessages={refetchSpaceMessages}
            />
          )}
        </div>
        <div className="chat-input">
          <ChatInput
            quotedMessage={quotedMessage}
            setQuotedMessage={setQuotedMessage}
            value={value}
            setValue={setValue}
            sendingMessage={sendingMessage}
            handleSendMessage={(message) =>
              handleSendMessage(message, attachments)
            }
            handleAttachmentsChange={setAttachments}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </div>
      </div>

      {openThread !== null && (
        <div className="resizer">
          <span onMouseDown={handleMouseDown}></span>
        </div>
      )}
      {openThread !== null && (
        <div className="thread-div">
          <div className="thread-header">
            <span>Thread</span>
            <span
              onClick={() => {
                closeThread();
                setThreadQuotedMessage(null);
              }}
              className="close-btn"
            >
              <RxCross2 size={22} />
            </span>
          </div>
          <div className="thread-chat-div">
            {loadingThreadMessages ? (
              <>
                <Skeleton className="skelton-wrapper" active />
              </>
            ) : (
              <MessageThread
                openThread={openThread}
                selectedSpace={selectedSpace}
                threadQuotedMessage={threadQuotedMessage}
                setThreadQuotedMessage={setThreadQuotedMessage}
                threadMessages={threadMessages}
                refetchThreadMessages={refetchThreadMessages}
              />
            )}
          </div>
          <div className="chat-input">
            <ThreadInput
              threadQuotedMessage={threadQuotedMessage}
              setThreadQuotedMessage={setThreadQuotedMessage}
              value={threadValue}
              setValue={setThreadValue}
              sendingMessage={sendingMessageInThread}
              handleSendMessage={(message) =>
                handleSendMessageInThread(message, threadSelectedFiles)
              }
              handleAttachmentsChange={setThreadAttachments}
              selectedFiles={threadSelectedFiles}
              setSelectedFiles={setThreadSelectedFiles}
            />
          </div>
        </div>
      )}

      <AddMembersModel
        isModelOpen={isModelOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
    </MainContainer>
  );
};

export default ChatSection;
