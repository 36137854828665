import React, { useState, useEffect, useRef } from "react";
import { MainContainer, TabContainer, TabButton, TabContent } from "./styles";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import FirstColumn from "../../components/support/FirstColumn/FirstColumn";
import SecondColumn from "../../components/support/SecondColumn/SecondColumn";
import ThirdColumn from "../../components/support/ThirdColumn/ThirdColumn";
import FourthColumn from "../../components/support/FourthColumn/FourthColumn";
import EmailComposer from "../../components/support/FirstColumn/composer/EmailComposer";
import { Drawer } from "antd";
import AssociateContact from "../../components/support/AssociateContact/AssociateContact";
import { CloseOutlined } from "@ant-design/icons";
import {
  handleAddCurrentActiveChatTab,
  handleAddCurrentActiveChatTabKey,
} from "../../redux/app/appSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddCurrentActiveChatThread,
  handleAddOpenConversationDetails,
} from "../../redux/app/appSlice";
import noOpenConversation from "./no-open-conversation.svg";
import useGetData from "../../hooks/useGetData";
import SupportSearch from "../../components/support/SupportSearch/SupportSearch";
import { useParams } from "react-router-dom";
import { InstanceWithAuth } from "../../App";
const Support = () => {
  const [selectedEmailOrChat, setSelectedEmailOrChat] = useState(null);
  const [selectedEmailIdForStyling, setSelectedEmailIdForStyling] =
    useState(null);
  const [status, setStatus] = useState(0);
  const [isAllClosedSelected, setIsAllClosedSelected] = useState(false);
  const [isComposerVisible, setIsComposerVisible] = useState(false);
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState("addExisting");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [searchParm, setSearchParm] = useState("");
  const [order, setOrder] = useState("DESC");
  const dispatch = useDispatch();
  const refetchEmailsRef = useRef(null);
  const refetchCountRef = useRef(null);
  const refetchEmailRef = useRef(null);
  const id = localStorage.getItem("id");
  const folder = localStorage.getItem("folder");
  const { app, auth } = useSelector((state) => state);
  const [loadingEmailThread, setLoadingEmailThread] = useState(false);
  const [email, setEmail] = useState(null);
  const { mailID, mailId, folderId, messageId } = useParams();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderClicked, setFolderClicked] = useState(false);
  const [department, setDepartment] = useState(null);

  useEffect(() => {
    if (!mailId && !folderId) {
      setSelectedFolder("All Open");
    }
  }, [mailId, folderId]);

  const refetchData = async () => {
    try {
      setLoadingEmailThread(true);
      const response = await InstanceWithAuth.get(
        `support/emails/${selectedEmailOrChat ? selectedEmailOrChat : mailID}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setEmail(response?.data);
        dispatch(handleAddOpenConversationDetails(response?.data?.data));
      }
      setLoadingEmailThread(false);
    } catch (error) {
      setLoadingEmailThread(false);
    }
  };

  useEffect(() => {
    refetchData();
  }, [selectedEmailOrChat]);

  const normalizedFolder = selectedFolder?.toLowerCase();
  const normalizedFolderEndpoints = {
    chat: `live-chat?query=${searchParm}&order=${order}&filter_by=is_closed&is_closed=0`,
    "all open": `all-conversations?query=${searchParm}&order=${order}`,
    "all closed": `all-closed-conversations?query=${searchParm}&order=${order}`,
    inbox: `support/emails/inbox?status=${status}&order_by=created_at&order=${order}`,
    sent: `support/emails/sent?status=${status}&order_by=created_at&order=${order}`,
    spam: `support/emails/spam?status=${status}&order_by=created_at&order=${order}`,
    trash: `support/emails/trash?status=${status}&order_by=created_at&order=${order}`,
  };

  const endpoint =
    department?.id &&
    (normalizedFolder in normalizedFolderEndpoints
      ? `${normalizedFolderEndpoints[normalizedFolder]}&department_id=${department.id}`
      : `${normalizedFolderEndpoints["all open"]}&department_id=${department.id}`);

  const {
    data: initialEmails,
    error: emailError,
    loading,
    refetchData: refetchEmails,
  } = useGetData(endpoint || null);

  // Refetch emails when department changes
  useEffect(() => {
    if (department?.id) {
      setIsInitialLoad(true);
      setSelectedEmailOrChat(null);
      refetchEmails();
    }
  }, [department]);

  const showDrawerContactDrawer = () => {
    setOpenContactDrawer(true);
  };
  const onCloseContactDrawer = () => {
    setOpenContactDrawer(false);
  };

  const handleFolderSelect = (folder, folderKey) => {
    dispatch(handleAddCurrentActiveChatTab(folder));
    dispatch(handleAddCurrentActiveChatTabKey(folderKey));
    if (folder != "All Open") {
      dispatch(handleAddOpenConversationDetails(""));
    }
    setIsInitialLoad(true);
    setFolderClicked(true);
    setSelectedFolder(folder);
    setStatus(0);
    // setSelectedEmailOrChat(null);
    setSelectedEmails([]);
  };

  useEffect(() => {
    setSelectedEmailOrChat(null);
    setSelectedEmailIdForStyling(null);
  }, [selectedFolder]);

  const handleEmailSelect = (
    emailOrChatId,
    comapnyId,
    conversationsDetails
  ) => {
    dispatch(handleAddCurrentActiveChatThread(emailOrChatId));
    if (id && folder) {
      localStorage.removeItem("id");
      localStorage.removeItem("folder");
    }
    setSelectedEmailOrChat(emailOrChatId);
    setSelectedCompanyId(comapnyId);
    setSelectedEmailIdForStyling(conversationsDetails?.communication_id);
  };

  const handleOwnerChange = (event) => {
    const newOwner = event.target.value;
  };

  const openComposer = () => {
    setIsComposerVisible(true);
  };

  const closeComposer = () => {
    setIsComposerVisible(false);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (id && folder) {
      if (folder === "inbox") {
        setSelectedFolder("Inbox");
      } else if (folder === "sent") {
        setSelectedFolder("Sent");
      } else if (folder === "spam") {
        setSelectedFolder("Spam");
      } else if (folder === "trash") {
        setSelectedFolder("Trash");
      } else {
        setSelectedFolder("All Closed");
      }
      // setSelectedEmailOrChat(id);
      // alert(id, folder);
    }
  }, [id, folder]);

  // useEffect(() => {
  //   if (initialEmails?.data && isInitialLoad) {
  //     setIsInitialLoad(false);
  //     if (mailId && folderId) {
  //     } else if (
  //       selectedFolder === "All Open" ||
  //       selectedFolder === "All Closed"
  //     ) {
  //       dispatch(
  //         handleAddOpenConversationDetails(initialEmails?.data?.data[0])
  //       );
  //       setSelectedEmailOrChat(initialEmails?.data?.data[0]?.id);
  //       setSelectedEmailIdForStyling(
  //         initialEmails?.data?.data[0]?.communication_id
  //       );
  //     } else {
  //       dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
  //       setSelectedEmailOrChat(initialEmails?.data[0]?.id);
  //       setSelectedEmailIdForStyling(initialEmails?.data[0]?.communication_id);
  //     }
  //   }
  // }, [initialEmails]);

  useEffect(() => {
    if (!initialEmails?.data) {
      return;
    }

    if (mailId && folderId) {
      if (!isInitialLoad) {
        return;
      }

      if (folderClicked && isInitialLoad) {
        if (selectedFolder === "All Open" || selectedFolder === "All Closed") {
          dispatch(
            handleAddOpenConversationDetails(initialEmails?.data?.data[0])
          );
          setSelectedEmailOrChat(initialEmails?.data?.data[0]?.id);
          setSelectedEmailIdForStyling(
            initialEmails?.data?.data[0]?.communication_id
          );
          // setIsInitialLoad(false);
          // setFolderClicked(false);
          //
        } else {
          dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
          setSelectedEmailOrChat(initialEmails?.data[0]?.id);
          setSelectedEmailIdForStyling(
            initialEmails?.data[0]?.communication_id
          );
          // setIsInitialLoad(false);
          // setFolderClicked(false);
        }
      } else {
        const selectedFolderData = [
          {
            key: "1",
            label: "Inbox",
          },
          {
            key: "2",
            label: "Sent",
          },
          {
            key: "3",
            label: "Spam",
          },
          {
            key: "4",
            label: "Trash",
          },
          {
            key: "5",
            label: "All Open",
          },
          {
            key: "6",
            label: "Chat",
          },
          {
            key: "7",
            label: "All Closed",
          },
        ]?.find((folder) => folder.key === folderId.toString());

        if (selectedFolderData) {
          setSelectedFolder(selectedFolderData.label);

          const emailToSet = Array.isArray(initialEmails?.data?.data)
            ? initialEmails?.data?.data.find(
                (email) => email.id?.toString() === mailId?.toString()
              )
            : Array.isArray(initialEmails?.data)
            ? initialEmails?.data.find(
                (email) => email?.id?.toString() === mailId?.toString()
              )
            : null;

          if (emailToSet) {
            dispatch(handleAddCurrentActiveChatTab(selectedFolderData?.label));
            dispatch(handleAddOpenConversationDetails(emailToSet));
            setSelectedEmailOrChat(emailToSet?.id);
            setSelectedEmailIdForStyling(emailToSet?.communication_id);
          }
        }
      }
    } else if (
      selectedFolder === "All Open" ||
      selectedFolder === "All Closed"
    ) {
      if (isInitialLoad) {
        setIsInitialLoad(false);
      } else {
        return;
      }
      dispatch(handleAddOpenConversationDetails(initialEmails?.data?.data[0]));
      setSelectedEmailOrChat(initialEmails?.data?.data[0]?.id);
      setSelectedEmailIdForStyling(
        initialEmails?.data?.data[0]?.communication_id
      );
      // dispatch(handleAddCurrentActiveChatTabKey(folderId));
    } else {
      if (isInitialLoad) {
        setIsInitialLoad(false);
      } else {
        return;
      }
      dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
      setSelectedEmailOrChat(initialEmails?.data[0]?.id);
      setSelectedEmailIdForStyling(initialEmails?.data[0]?.communication_id);
      // dispatch(handleAddCurrentActiveChatTabKey(folderId));
    }
  }, [initialEmails]);

  return (
    <div style={{ position: "relative" }}>
      <CommonHaeder firstBcItem={"Support"} firstBcIcon={"support"} />
      {app?.supportSearchShow ? (
        <SupportSearch />
      ) : (
        <>
          <MainContainer>
            <div
              className="first-column"
              style={{
                flex: app?.sidebarCollapsed ? "1.7" : "0.7",
              }}
            >
              <FirstColumn
                status={status}
                selectedFolder={selectedFolder}
                onFolderSelect={handleFolderSelect}
                isAllClosedSelected={isAllClosedSelected}
                openComposer={openComposer}
                selectedEmailId={selectedEmailOrChat}
                setRefetchCount={(refetchFn) => {
                  refetchCountRef.current = refetchFn;
                }}
                setSearchParm={setSearchParm}
                department={department}
                setDepartment={setDepartment}
              />
            </div>

            <div className="second-column">
              <SecondColumn
                isInitialLoad={isInitialLoad}
                setIsInitialLoad={setIsInitialLoad}
                status={status}
                selectedFolder={selectedFolder}
                onEmailSelect={handleEmailSelect}
                selectedEmailId={selectedEmailOrChat}
                setRefetchEmails={(refetchFn) => {
                  refetchEmailsRef.current = refetchFn;
                }}
                selectedEmails={selectedEmails}
                setSelectedEmails={setSelectedEmails}
                id={id}
                setSelectedCompanyId={setSelectedCompanyId}
                searchParm={searchParm}
                initialEmails={initialEmails}
                refetchEmails={refetchEmails}
                order={order}
                setOrder={setOrder}
                loading={loading}
                selectedEmailIdForStyling={selectedEmailIdForStyling}
              />
            </div>

            {initialEmails?.data?.length <= 0 ||
            initialEmails?.data?.data?.length <= 0 ? (
              <div className="empty-section">
                <img className="svg" src={noOpenConversation} alt="" />
                <span className="h2">No open conversation here</span>
                <span className="h3">You're all caught up.</span>
              </div>
            ) : (
              <>
                <div className="third-column">
                  {selectedEmailOrChat && (
                    <ThirdColumn
                      selectedEmailId={selectedEmailOrChat}
                      onOwnerChange={handleOwnerChange}
                      refetchEmails={refetchEmailsRef.current}
                      refetchCount={refetchCountRef.current}
                      showDrawerContactDrawer={showDrawerContactDrawer}
                      selectedEmails={selectedEmails}
                      setSelectedEmails={setSelectedEmails}
                      selectedFolder={selectedFolder}
                      id={id}
                      refetchData={refetchData}
                      loadingEmailThread={loadingEmailThread}
                      email={email}
                    />
                  )}
                </div>
                <div className="fourth-column">
                  {selectedEmailOrChat && (
                    <FourthColumn
                      showDrawerContactDrawer={showDrawerContactDrawer}
                      onCloseContactDrawer={onCloseContactDrawer}
                      selectedEmailId={selectedEmailOrChat}
                      setSelectedEmailOrChat={setSelectedEmailOrChat}
                      refetchCount={refetchCountRef.current}
                      refetchEmails={refetchEmailsRef.current}
                      selectedEmails={selectedEmails}
                      setSelectedEmails={setSelectedEmails}
                      selectedFolder={selectedFolder}
                      selectedCompanyId={selectedCompanyId}
                      setRefetchEmail={(refetchFn) => {
                        refetchEmailRef.current = refetchFn;
                      }}
                      id={id}
                      refetchEmail={refetchData}
                      refetchData={refetchData}
                      loading={loadingEmailThread}
                      email={email}
                    />
                  )}
                </div>
              </>
            )}

            <EmailComposer
              refetchEmails={refetchEmailsRef.current}
              selectedEmailId={selectedEmailOrChat}
              visible={isComposerVisible}
              onClose={closeComposer}
            />
          </MainContainer>

          <Drawer
            className="drawer"
            width={600}
            padding={0}
            paddingLG={0}
            paddingXS={0}
            onClose={onCloseContactDrawer}
            open={openContactDrawer}
            headerStyle={{
              backgroundColor: "#4cbbcb",
              color: "#fff",
            }}
            closeIcon={
              <CloseOutlined style={{ color: "white", fontSize: "bold" }} />
            }
            title={
              activeTab === "addExisting"
                ? "Add existing Contact"
                : "Create and associate contact"
            }
          >
            {/* Custom tabs navigation */}
            <TabContainer>
              <TabButton
                isActive={activeTab === "addExisting"}
                onClick={() => handleTabChange("addExisting")}
              >
                Add existing
              </TabButton>
              <TabButton
                isActive={activeTab === "createNew"}
                onClick={() => handleTabChange("createNew")}
              >
                Create new
              </TabButton>
            </TabContainer>

            <TabContent>
              {activeTab === "addExisting" && (
                <AssociateContact
                  type="addExisting"
                  onCloseContactDrawer={onCloseContactDrawer}
                  selectedEmailOrChat={selectedEmailOrChat}
                  refetchEmails={refetchEmailsRef.current}
                  refetchEmail={refetchEmailRef.current}
                  refetchData={refetchData}
                />
              )}
              {activeTab === "createNew" && (
                <AssociateContact
                  type="createNew"
                  onCloseContactDrawer={onCloseContactDrawer}
                  selectedEmailOrChat={selectedEmailOrChat}
                  refetchEmails={refetchEmailsRef.current}
                  refetchEmail={refetchEmailRef.current}
                  refetchData={refetchData}
                />
              )}
            </TabContent>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default Support;
