import React, { useState } from "react";
import { Container, ContentContainer, DrawerContainer } from "./styles";
import _ from "lodash";
import SupportHeader from "./supportHeader/SupportHeaderr";
import Ticket from "./ticket/Ticket";
import Spinner from "../../../../common/Spinner";
import Unknown from "../Unknown/Unknown";
import { Button, Drawer } from "antd";
import UserDetailSidebar from "../../../userList/userDetailSidebar/UserDetailSidebar";
import { BsArrowLeft } from "react-icons/bs";
import { CloseOutlined } from "@ant-design/icons";
import { IoArrowBack } from "react-icons/io5";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
const TicketDetails = ({
  userID,
  selectedEmailId,
  email,
  showDrawerContactDrawer,
  onCloseContactDrawer,
  refetchData,
  selectedCompanyId,
  selectedFolder,
  senderName,
  refetchEmail,
}) => {
  const { app } = useSelector((state) => state);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <DrawerContainer>
      <Container>
        <>
          {app?.allOpenConversationDetails?.is_unknown_visitor ? (
            <Unknown
              email={email}
              selectedEmailId={selectedEmailId}
              showDrawerContactDrawer={showDrawerContactDrawer}
            />
          ) : (
            <>
              <SupportHeader
                emailData={email}
                userID={userID}
                showDrawer={showDrawer}
                refetchData={refetchData}
                selectedCompanyId={selectedCompanyId}
                email={app?.allOpenConversationDetails}
                selectedFolder={selectedFolder}
                senderName={senderName}
              />
            </>
          )}
          <ContentContainer>
            <Drawer
              width={400}
              onClose={onClose}
              open={open}
              title="Contact Details"
              style={{
                padding: "0px",
                margin: "0px",
              }}
              closeIcon={
                <IoMdArrowRoundBack
                  size={32}
                  style={{ color: "black", fontSize: "30px" }}
                />
              }
            >
              <UserDetailSidebar
                open={true}
                canHide={false}
                supportHeader={true}
                userID={email?.data?.contact_id}
                additionalTabs={true}
                selectedEmailId={selectedEmailId}
              />
            </Drawer>
            <Ticket
              userID={userID}
              selectedEmailId={selectedEmailId}
              email={email}
              refetchData={refetchData}
              refetchEmail={refetchEmail}
            />
          </ContentContainer>
        </>
      </Container>
    </DrawerContainer>
  );
};

export default TicketDetails;
