import React, { useState, useEffect } from 'react'
import { FaEnvelope, FaRegTrashAlt, FaRegCommentDots } from 'react-icons/fa'
import {
  SecondColumnContainer,
  HiddenCheckboxInput,
  StyledCheckbox,
  StyledCheckboxContainer,
} from './styles'
import { Dropdown, Menu, Skeleton, Space } from 'antd'
import moment from 'moment'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import Echo from 'laravel-echo'
import { useDispatch } from 'react-redux'
import DataNotFound from '../../../../common/DataNotFound'
import { InstanceWithAuth } from '../../../../App'
import { handleAddOpenConversationDetails } from '../../../../redux/app/appSlice'
const EmailsList = ({
  onEmailSelect,
  selectedEmailId,
  selectedFolder,
  status,
  setRefetchEmails,
  isInitialLoad,
  setIsInitialLoad,
  selectedEmails,
  setSelectedEmails,
  id,
  setSelectedCompanyId,
  searchParm,
  initialEmails,
  order,
  setOrder,
  loading,
  setSelectedEmailOrChat,
}) => {
  const [hoveredEmailId, setHoveredEmailId] = useState(null)
  const [isFolderChange, setIsFolderChange] = useState(false)
  const [joinedChats, setJoinedChats] = useState([])
  const [emails, setEmails] = useState('')
  const { auth, app } = useSelector((state) => state)
  const dispatch = useDispatch()

  console.log('initialEmails', initialEmails)

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'pusher',
      key: 'local',
      cluster: 'your-app-cluster',
      wsHost: `${process.env.REACT_APP_SOCKET_BASE_URL}`,
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
      disableStats: true,
    })
    echo.channel('chat.new').listen('ChatNew', (event) => {
      console.log('New event received:', event)
      setEmails((prevEmails) =>
        Array.isArray(prevEmails) ? [...prevEmails, event] : [event]
      )
    })
    echo.connector.pusher.connection.bind('connected', () => {
      console.log(
        'Successfully connected to Pusher to listing new chat request'
      )
    })
    return () => {
      echo.disconnect()
    }
  }, [])

  useEffect(() => {
    if (initialEmails?.data) {
      setEmails(initialEmails)
    }
  }, [initialEmails])

  const SortedAllOpenData = initialEmails?.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at)
  })

  const getIcon = (type) => {
    switch (type) {
      case 'SupportEmails':
        return <FaEnvelope />
      case 'trash':
        return <FaRegTrashAlt />
      case 'LiveChat':
        return <FaRegCommentDots />
      default:
        return <FaEnvelope />
    }
  }

  const getIconsForAllChat = (type) => {
    switch (type) {
      case 'SupportEmails':
        return <FaEnvelope />
      case 'trash':
        return <FaRegTrashAlt />
      case 'LiveChat':
        return <FaRegCommentDots />
      default:
        return <FaEnvelope />
    }
  }

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevSelected) => {
      if (prevSelected?.some((e) => e.id === email.id)) {
        return prevSelected.filter((e) => e.id !== email.id)
      } else {
        return [...prevSelected, email]
      }
    })
  }

  const handleSelectAllChange = () => {
    setSelectedEmails((prevSelected) =>
      prevSelected?.length === initialEmails?.length
        ? []
        : initialEmails?.map((email) => email)
    )
  }

  const truncateText = (text, maxLength) =>
    text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text

  const formatTime = (time) => {
    const now = moment()
    const updatedAt = moment(time)

    if (now.diff(updatedAt, 'minutes') < 60) {
      return `${now.diff(updatedAt, 'minutes')}m`
    } else if (now.diff(updatedAt, 'hours') < 24) {
      return `${now.diff(updatedAt, 'hours')}h`
    } else if (now.diff(updatedAt, 'days') === 1) {
      return 'Yesterday'
    } else {
      return updatedAt.format('MMM D')
    }
  }

  const handleSortOrderChange = (newOrder) => {
    setOrder(newOrder)
  }

  const sortingMenu = (
    <Menu>
      <Menu.Item onClick={() => handleSortOrderChange('DESC')}>
        Newest
      </Menu.Item>
      <Menu.Item onClick={() => handleSortOrderChange('ASC')}>Oldest</Menu.Item>
    </Menu>
  )

  const handleAddConverstionDetails = (conversationsDetails, emailId) => {
    setSelectedEmailOrChat(emailId)
    dispatch(handleAddOpenConversationDetails(conversationsDetails))
  }

  return (
    <SecondColumnContainer>
      {loading ? (
        <Skeleton active style={{ margin: '10px', width: 'auto' }}></Skeleton>
      ) : (
        <>
          <div className="header">
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                gap: '7px',
              }}
            >
              <StyledCheckboxContainer>
                <HiddenCheckboxInput
                  checked={selectedEmails?.length === initialEmails?.length}
                  onChange={handleSelectAllChange}
                />
                <StyledCheckbox
                  checked={selectedEmails?.length === initialEmails?.length}
                  onChange={handleSelectAllChange}
                />
              </StyledCheckboxContainer>

              {selectedEmails?.length > 0 && (
                <span className="selected-mails">
                  {selectedEmails?.length + ' ' + 'Selected'}
                </span>
              )}
            </div>

            {selectedEmails?.length <= 0 && (
              <Dropdown overlay={sortingMenu} trigger={['click']}>
                <Space className="btn-title">
                  {order === 'DESC' ? 'Newest' : 'Oldest'}
                  <MdOutlineKeyboardArrowDown
                    width="1rem"
                    height="1rem"
                    size="15px"
                  ></MdOutlineKeyboardArrowDown>
                </Space>
              </Dropdown>
            )}
          </div>
          <div className="email-list">
            {initialEmails?.length > 0 &&
              SortedAllOpenData?.map((email) => (
                <div
                  key={email.id}
                  className={`email-item ${
                    email.id === selectedEmailId ? 'selected' : ''
                  }`}
                  onClick={() => handleAddConverstionDetails(email, email.id)}
                  onMouseEnter={() => setHoveredEmailId(email.id)}
                  onMouseLeave={() => setHoveredEmailId(null)}
                >
                  <div className="email-header">
                    {hoveredEmailId === email.id ||
                    selectedEmails?.some((e) => e.id === email.id) ? (
                      <div className="email-checkbox">
                        <StyledCheckboxContainer>
                          <HiddenCheckboxInput
                            checked={selectedEmails?.some(
                              (e) => e.id === email.id
                            )} // Check if the email is selected
                            onChange={() => handleCheckboxChange(email)} // Pass the full email object
                          />
                          <StyledCheckbox
                            checked={selectedEmails?.some(
                              (e) => e.id === email.id
                            )} // Check if the email is selected
                            onChange={() => handleCheckboxChange(email)} // Pass the full email object
                          />
                        </StyledCheckboxContainer>
                      </div>
                    ) : (
                      <div
                        className={`email-icon ${
                          email.id === selectedEmailId
                            ? 'selected-email-icon'
                            : ''
                        }`}
                      >
                        {selectedFolder == 'All Open'
                          ? getIconsForAllChat(email?.Model)
                          : getIcon(email?.Model)}
                      </div>
                    )}

                    <div className="email-details">
                      <div className="email-top-row">
                        <span
                          className={
                            email?.read_status == 1 || email?.owner != null
                              ? 'email-sender'
                              : 'unread-email-sender'
                          }
                        >
                          {truncateText(
                            email?.Model == 'SupportEmails'
                              ? email?.sender_name
                              : email?.contact_id != null
                              ? email?.contact_name
                              : email.guest_name,
                            app?.sidebarCollapsed ? 15 : 8
                          )}
                        </span>
                        <span
                          className={
                            email?.read_status == 1 || email?.owner != null
                              ? 'email-time'
                              : 'unread-email-time'
                          }
                        >
                          {formatTime(email.created_at)}
                        </span>
                      </div>
                      <div
                        className={
                          email?.read_status == 1
                            ? 'email-subject'
                            : 'unread-email-subject'
                        }
                      >
                        {email?.Model == 'SupportEmails'
                          ? truncateText(email.subject, 15)
                          : truncateText(email?.owner, 15)}
                      </div>
                      <div className="email-preview">
                        {truncateText(
                          email.email_body?.replace(/<\/?[^>]+(>|$)/g, ''),
                          27
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </SecondColumnContainer>
  )
}

export default EmailsList
