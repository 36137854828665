import React, { useState } from 'react'
import { Collapse, Skeleton } from 'antd'
import { CompanyCards, Container, ContentContainer } from './styles'
import './styles'
import { useNavigate } from 'react-router-dom'
import useGetData from '../../../../../hooks/useGetData'
import DataNotFound from '../../../../../common/DataNotFound'
import FormatDate from '../../../../../common/FormatDate'
import GetLogo from '../../../../getlogo/getlogo'
import StatusLabelButton from '../../../../statusLabelButton/StatusLabelButton'
const { Panel } = Collapse

export default function PhoneNumberlist({ userID, companyData }) {
  const {
    data: phoneNumberData,
    loading,
    error: phoneNumberErro,
  } = useGetData(
    `live-answering/phone-numbers?company_id=${companyData?.data?.id}`
  )
  const [collopasedKey, setCollopasedKey] = useState(false)
  const navigate = useNavigate()

  {
    console.log(phoneNumberData?.data, 'phoneNumberData?.data')
  }
  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Phone Number" key="1" className="account-panel">
          <ContentContainer>
            {loading ? (
              <Skeleton active></Skeleton>
            ) : phoneNumberData?.data?.length == 0 ? (
              <DataNotFound label={'No Phone Number Found'} />
            ) : (
              phoneNumberData?.data?.map((number) => (
                <CompanyCards>
                  <div className="company-labels">
                    <p className="company-name">
                      {number?.virtual_phone_number}
                    </p>
                    <span className="office"></span>
                  </div>
                  <div className="time-details">
                    <div className="btns">
                      <StatusLabelButton
                        Data={companyData?.data}
                      ></StatusLabelButton>
                      <button
                        className="view-btn"
                        onClick={() =>
                          navigate(
                            `/${companyData?.data?.contact_id}/${companyData?.data?.id}/${number?.id}/edit-number`
                          )
                        }
                      >
                        <span className="btn-text">View</span>
                      </button>
                    </div>
                  </div>
                </CompanyCards>
              ))
            )}
            {/* <Collapse className="form-collapased">
              <Panel header="View All" key="1-1" className="panel">
                {loading ? (
                  <Skeleton active></Skeleton>
                ) : data?.data?.length == 0 ? (
                  <DataNotFound label={'No Company Found'} />
                ) : (
                  <CompanyCards>
                    <div className="company-labels">
                      <p className="company-name">Gonzalez LLC</p>
                      <span className="office">Premium Business Mailbox</span>
                    </div>
                    <div className="time-details">
                      <p className="date">11 October 2023</p>
                      <div className="btns">
                        <button className="active-btn">
                          <span className="btn-text">Active</span>
                        </button>
                        <button className="view-btn">
                          <span className="btn-text">View</span>
                        </button>
                      </div>
                    </div>
                  </CompanyCards>
                )}
              </Panel>
            </Collapse> */}
          </ContentContainer>
        </Panel>
      </Collapse>
    </Container>
  )
}
