import React, { useState, useRef, useEffect } from "react";
import { MainContainer } from "./styles";
import ChatInput from "./chatInput/ChatInput";
import ChatMessages from "./ChatMessages/ChatMessages";
import { Skeleton } from "antd";
import { InstanceWithAuth } from "../../../../App";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import useGetData from "../../../../hooks/useGetData";

const ChatSection = ({
  spaceMessages,
  loadingMessages,
  selectedSpace,
  refetchSpaceMessages,
  quotedMessage,
  setQuotedMessage,
  firstRender,
  setFirstRender,
  openThread,
  setOpenThread,
  mainDivWidth,
}) => {
  const [value, setValue] = useState("");
  const { auth } = useSelector((state) => state);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const containerRef = useRef(null);
  const chatDivRef = useRef(null);

  useEffect(() => {
    if (chatDivRef.current) {
      if (firstRender || (scrollToBottom && spaceMessages)) {
        chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
        // setFirstRender(false);
        // setScrollToBottom(false);
      } else {
        const isAtBottom =
          chatDivRef.current.scrollHeight - chatDivRef.current.scrollTop <=
          chatDivRef.current.clientHeight + 50;

        if (isAtBottom) {
          chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
        }
      }
    }
  }, [spaceMessages]);

  const toggleThread = (id) => {
    setOpenThread(openThread === id ? null : id);
  };

  const handleSendMessage = async (message, files) => {
    setSendingMessage(true);
    setQuotedMessage(null);
    const formData = new FormData();
    formData.append("message", message || "");
    formData.append("replied_to", quotedMessage ? quotedMessage?.id : "");
    if (files && files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`attachments[${index}]`, file, file.name);
      });
    }

    try {
      const response = await InstanceWithAuth.post(
        `internal-chat/spaces/${selectedSpace?.id}/add-message`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.status === 201) {
        setValue("");
        // refetchSpaceMessages();
        setSelectedFiles([]);
        setSendingMessage(false);
        setQuotedMessage(null);
        await refetchSpaceMessages();
        setScrollToBottom(true);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
      setSendingMessage(false);
    }
  };

  return (
    <MainContainer mainDivWidth={mainDivWidth} ref={containerRef}>
      <div className="main-div">
        <div className="chat-div" ref={chatDivRef}>
          {loadingMessages && firstRender ? (
            <>
              <Skeleton className="skelton-wrapper" active />
            </>
          ) : (
            <ChatMessages
              openThread={openThread}
              setOpenThread={setOpenThread}
              toggleThread={toggleThread}
              setQuotedMessage={setQuotedMessage}
              spaceMessages={spaceMessages}
              refetchSpaceMessages={refetchSpaceMessages}
            />
          )}
        </div>
        <div className="chat-input">
          <ChatInput
            quotedMessage={quotedMessage}
            setQuotedMessage={setQuotedMessage}
            value={value}
            setValue={setValue}
            sendingMessage={sendingMessage}
            handleSendMessage={(message) =>
              handleSendMessage(message, attachments)
            }
            handleAttachmentsChange={setAttachments}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </div>
      </div>
    </MainContainer>
  );
};

export default ChatSection;
