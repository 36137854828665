import React, { useState, useEffect } from 'react'
import './styles'
import { Container, Icons, Title, UserAvatar } from './styles'
import GetLogo from '../../../../getlogo/getlogo'
import userListSideBar from '../../../../../assets/userListSideBar.jpg'
import profilePlaceHolder from '../../../../../assets/profilePlaceholder.jpg'
import { MdPreview } from 'react-icons/md'
import { VscOpenPreview } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom'
import useGetData from '../../../../../hooks/useGetData'
import { useSelector, useDispatch } from 'react-redux'
import { handSelectUserFlowTab } from '../../../../../redux/app/appSlice'
import { InstanceWithAuth } from '../../../../../App'
import Swal from 'sweetalert2'
import { LuMessagesSquare } from 'react-icons/lu'
import { IoIosChatboxes } from 'react-icons/io'
import { BsMailboxFlag } from 'react-icons/bs'
import { FaRegUserCircle } from 'react-icons/fa'
import { MdPayment } from 'react-icons/md'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { IoMdCard } from 'react-icons/io'
import { Popconfirm, Skeleton } from 'antd'
import Spinner from '../../../../../common/Spinner'
import CustomTooltip from '../../../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip'
import NameAvatar from '../../../../../common/NameAvatar'
import { LuExternalLink } from 'react-icons/lu'
import { VscChromeClose } from 'react-icons/vsc'
const UserView = ({
  setOpen,
  userID,
  setSideBarOpen,
  hanleSearchContact,
  canHide,
}) => {
  const navigate = useNavigate()
  const handleClose = () => {
    setSideBarOpen(false)
    setOpen()
  }
  const {
    data: contactData,
    loading: contactDataLoading,
    error: contactDataErro,
    refetchData: refetchConatctData,
  } = useGetData(`contacts/${userID}`)
  const [userDeleting, setUserDeleting] = useState(false)
  const { auth } = useSelector((state) => state)
  const dispatch = useDispatch()

  const handleRedirect = (tabId) => {
    dispatch(handSelectUserFlowTab(tabId))
    navigate(`/users/${userID}`)
  }
  useEffect(() => {
    refetchConatctData()
  }, [userID])

  const redirectToUrl = (dashboard_login_email) => {
    const baseUrl = 'https://clone.starthubcenters.com'
    const email = decodeURIComponent(dashboard_login_email)
    const params = `autologin=true&email=${email}`
    window.open(`${baseUrl}?${params}`, '_blank')
  }

  return (
    <Container>
      <Title>
        <span className="label"></span>
        <div className="icons">
          <span className="icon" onClick={() => handleRedirect('1')}>
            <LuExternalLink size={17}></LuExternalLink>
          </span>
          {canHide && (
            <span className="icon" onClick={handleClose}>
              {/* {GetLogo('fillClose')} */}
              <VscChromeClose size={18}></VscChromeClose>
            </span>
          )}
        </div>
      </Title>

      <UserAvatar>
        <div className="user-img">
          {contactDataLoading ? (
            <Skeleton.Avatar active size={107} shape="circle" />
          ) : contactData?.data?.avatar ? (
            <img src={contactData?.data?.avatar} alt="" />
          ) : (
            <NameAvatar
              userName={`${contactData?.data?.first_name} ${contactData?.data?.last_name}`}
            ></NameAvatar>
          )}
        </div>
        <div className="btns">
          {contactDataLoading ? (
            <Skeleton.Avatar active size={15} shape="circle" />
          ) : (
            <button
              className={
                contactData?.data?.user?.online ? 'manager-btn' : 'manager-btn'
              }
            >
              <CustomTooltip
                text={contactData?.data?.status_label}
                marginTop="-25px"
              >
                <div
                  className={
                    contactData?.data?.status_label == 'On Hold' &&
                    contactData?.data?.status == 0
                      ? 'on-hold'
                      : contactData?.data?.status == 0
                      ? 'in-active'
                      : 'active'
                  }
                >
                  <span className="btn-icon">{GetLogo('circle')}</span>
                </div>
              </CustomTooltip>
            </button>
          )}
          {contactDataLoading ? (
            <Skeleton.Avatar active size={15} shape="circle" />
          ) : (
            <button
              onClick={() =>
                redirectToUrl(contactData?.data?.dashboard_login_email)
              }
              className="impression-btn"
            >
              <span className="btn-icon">{GetLogo('eye')}</span>
            </button>
          )}
        </div>
        <div className="user-detail">
          {contactDataLoading ? (
            <Skeleton.Input active size={23} block="false" />
          ) : (
            <p className="user-name">
              {`${contactData?.data?.first_name}
            ${contactData?.data?.last_name}`}
            </p>
          )}
          {contactDataLoading ? (
            <Skeleton.Input active size={19.5} block="false" />
          ) : (
            <span className="user-id">
              ID: <span className="id">{contactData?.data?.id}</span>
            </span>
          )}
        </div>
      </UserAvatar>
      <Icons>
        <div className="icons">
          <CustomTooltip text={'Mail'} marginTop="-20px">
            {contactDataLoading ? (
              <Skeleton.Button
                active
                style={{ width: '105px', height: '67px' }}
                shape="default"
              />
            ) : (
              <span className="icon">{GetLogo('userMail')}</span>
            )}
          </CustomTooltip>
          <CustomTooltip text={'Call'} marginTop="-20px">
            {contactDataLoading ? (
              <Skeleton.Button
                active
                style={{ width: '105px', height: '67px' }}
                shape="default"
              />
            ) : (
              <span className="icon">{GetLogo('userCall')}</span>
            )}
          </CustomTooltip>
          <CustomTooltip text={'Text Message'} marginTop="-20px">
            <span className="icon">
              {contactDataLoading ? (
                <Skeleton.Button
                  active
                  style={{ width: '105px', height: '67px' }}
                  shape="default"
                />
              ) : (
                <IoIosChatboxes fill="#556373" size={28} />
              )}
            </span>
          </CustomTooltip>
        </div>
        <div className="icons">
          <CustomTooltip text={'Company'} marginTop="-20px">
            {contactDataLoading ? (
              <Skeleton.Button
                active
                style={{ width: '105px', height: '67px' }}
                shape="default"
              />
            ) : (
              <span className="icon" onClick={() => handleRedirect('3')}>
                {GetLogo('userCompany')}
              </span>
            )}
          </CustomTooltip>
          <CustomTooltip
            text={
              contactData?.data?.is_mailbox ? 'Virtual Mailbox' : 'Payments'
            }
            marginTop="-20px"
          >
            {contactDataLoading ? (
              <Skeleton.Button
                active
                style={{ width: '105px', height: '67px' }}
                shape="default"
              />
            ) : (
              <span
                className="icon"
                onClick={() =>
                  handleRedirect(contactData?.data?.is_mailbox ? '4' : '5')
                }
              >
                {contactData?.data?.is_mailbox ? (
                  <BsMailboxFlag fill="#556373" size={28} />
                ) : (
                  <IoMdCard fill="#556373" size={28} />
                )}
              </span>
            )}
          </CustomTooltip>
          <CustomTooltip text={'User Detail'} marginTop="-20px">
            {contactDataLoading ? (
              <Skeleton.Button
                active
                style={{ width: '105px', height: '67px' }}
                shape="default"
              />
            ) : (
              <span className="icon" onClick={() => handleRedirect('1')}>
                <FaRegUserCircle fill="#556373" size={28} />
              </span>
            )}
          </CustomTooltip>
        </div>
      </Icons>
    </Container>
  )
}

export default UserView
